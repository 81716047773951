import { base, baseSepolia } from '@wagmi/core/chains';
import { isFeatureEnabledStore } from 'cb-wallet-data/FeatureManager/classes/IsFeatureEnabledStore';

type UseShouldMakePaymasterCallArgs = {
  chainId: number;
  hasPaymasterURL?: boolean;
};

export function getShouldMakePaymasterCall({
  chainId,
  hasPaymasterURL,
}: UseShouldMakePaymasterCallArgs): boolean {
  // Feature flags
  const isAppPaymastersEnabled = isFeatureEnabledStore.getIsFeatureEnabled('scw_app_paymasters');
  const isBaseSepoliaPaymasterEnabled =
    isFeatureEnabledStore.getIsFeatureEnabled('scw_sepolia_paymaster');
  const isCloudPaymastersEnabled =
    isFeatureEnabledStore.getIsFeatureEnabled('scw_cloud_paymasters');

  // Conditions
  const isBase = chainId === base.id;
  const isBaseSepolia = chainId === baseSepolia.id;

  // If the app provides a paymaster URL and the app paymasters are enabled
  if (hasPaymasterURL) {
    return isAppPaymastersEnabled;
  }

  // If the chain is Base and the Cloud paymasters are enabled
  if (isBase) {
    return isCloudPaymastersEnabled;
  }

  // If the chain is Sepolia and the Sepolia paymaster feature is enabled
  if (isBaseSepolia) {
    return isBaseSepoliaPaymasterEnabled;
  }

  // Default to false for all other cases:
  // - The app did not provide a paymaster and the chain is neither Base nor Base Sepolia
  return false;
}
