import {
  EthereumWalletConfiguration,
  TxOrUserOpMetadataKey_txSource,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { EthereumUnsignedLegacyTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumUnsignedLegacyTx';
import { EthTxConfigLegacy } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/chainConfigs';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import {
  GenerateUnsignedTransactionArgs,
  GenerateUnsignedTransactionResult,
} from 'cb-wallet-data/stores/Transactions/methods/generateUnsignedTransaction';

import { getLegacyGasAndTxValue } from './utils/gas';

/**
 * Generate an unsigned legacy transaction
 */
export async function generateUnsignedLegacyTransaction({
  wallet,
  recipientAddress,
  transactionAmount,
  metadata,
  txOrUserOpConfig,
}: GenerateUnsignedTransactionArgs): GenerateUnsignedTransactionResult {
  const { dataBuffer, selectedGasPrice, selectedGasLimit, nonce, erc20ContractAddress } =
    txOrUserOpConfig as EthTxConfigLegacy;

  const {
    primaryAddress: fromAddress,
    network,
    currencyCode,
    blockchain,
    selectedIndex: walletIndex,
  } = wallet;
  const ethereumChain = network.asChain() as EthereumChain;
  if (!ethereumChain) throw NetworkError.invalidNetwork(network);

  const data = dataBuffer ?? Buffer.from(new Uint8Array(0));

  const txSource = metadata.get(TxOrUserOpMetadataKey_txSource)?.toLocaleString() ?? '';

  const { gasLimit, gasPrice, weiValue, gasEstimationStatus, l1GasFee } =
    await getLegacyGasAndTxValue({
      transactionAmount,
      wallet,
      data,
      recipientAddress,
      selectedGasPrice,
      selectedGasLimit,
      contractAddress: erc20ContractAddress,
      txSource,
    });

  // Generate the unsigned transaction with fetched details
  // throw error only when it's undefined explicitly.
  // we don't want `0` walletIndex to trigger error.
  if (walletIndex === undefined) throw EthereumError.unableToFindWallet;

  const configuration = EthereumWalletConfiguration;
  const feeCurrencyCode = configuration.currencyCodeForNetwork(network);
  const feeCurrencyDecimal = configuration.feeDecimalForNetwork(network);

  const [fromDomain, toDomain] = await fetchDomains([fromAddress, recipientAddress]);

  const transaction = EthereumUnsignedLegacyTx.createEtherTx({
    fromAddress,
    fromDomain,
    walletIndex,
    toAddress: recipientAddress,
    toDomain,
    nonce,
    weiValue,
    data,
    l1GasFee,
    gasPrice,
    gasLimit,
    network,
    currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    blockchain,
    metadata,
  });

  if (gasEstimationStatus === 'Success') {
    return {
      kind: 'Success',
      transaction,
      wallet,
      err: undefined,
    };
  }

  return {
    kind: 'Failure',
    transaction,
    wallet,
    err: EthereumError.unableToEstimateGas,
  };
}
