import { getERC20Balance } from 'cb-wallet-data/chains/AccountBased/Ethereum/Balance/getERC20Balance';
import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { EthereumUnsigned1559Tx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumUnsigned1559Tx';
import { EthTxConfig1559 } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/chainConfigs';
import { encodeERC20Transfer } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/encodeERC20Transfer';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import {
  GenerateUnsignedTransactionArgs,
  GenerateUnsignedTransactionResult,
} from 'cb-wallet-data/stores/Transactions/methods/generateUnsignedTransaction';

import { generateUnsigned1559Transaction } from './generateUnsigned1559Transaction';

/**
 * Generate ERC20 unsigned transaction
 */
export async function generateUnsignedERC201559Transaction({
  wallet,
  recipientAddress,
  transactionAmount,
  metadata,
  txOrUserOpConfig,
  isSponsoredTx,
  skipChecksumValidation = false,
}: GenerateUnsignedTransactionArgs): GenerateUnsignedTransactionResult {
  const { nonce } = txOrUserOpConfig as EthTxConfig1559;

  if (!wallet.contractAddress) throw EthereumError.invalidERC20;

  const transferValue =
    transactionAmount.kind === 'Amount'
      ? transactionAmount.value
      : await getERC20Balance(wallet.primaryAddress, wallet.contractAddress, wallet.network);

  const encodeResult = encodeERC20Transfer(
    recipientAddress,
    wallet.contractAddress,
    transferValue,
    skipChecksumValidation,
  );

  const config = {
    ...txOrUserOpConfig,
    txType: '1559' as const,
    dataBuffer: encodeResult.data,
  };

  const unsignedTxResult = await generateUnsigned1559Transaction({
    wallet,
    recipientAddress: encodeResult.toAddress,
    metadata,
    transactionAmount: { kind: 'Amount', value: 0n },
    txOrUserOpConfig: config,
    isSponsoredTx,
  });

  if (!(unsignedTxResult.transaction instanceof EthereumUnsigned1559Tx)) {
    throw EthereumError.unableToFindSignedTx;
  }

  const ethereumUnsignedTx: EthereumUnsigned1559Tx = unsignedTxResult.transaction;

  const [fromDomain, contractDomain, recipientDomain] = await fetchDomains([
    ethereumUnsignedTx.fromAddress,
    wallet.contractAddress,
    recipientAddress,
  ]);

  const transaction = EthereumUnsigned1559Tx.createERC20Tx({
    fromAddress: ethereumUnsignedTx.fromAddress,
    fromDomain,
    walletIndex: ethereumUnsignedTx.walletIndex,
    contractAddress: wallet.contractAddress,
    contractDomain,
    recipientAddress,
    recipientDomain,
    nonce,
    erc20Value: transferValue,
    data: ethereumUnsignedTx.data,
    maxFeePerGas: ethereumUnsignedTx.maxFeePerGas,
    maxPriorityFeePerGas: ethereumUnsignedTx.maxPriorityFeePerGas,
    gasLimit: ethereumUnsignedTx.gasLimit,
    network: ethereumUnsignedTx.network,
    currencyCode: ethereumUnsignedTx.currencyCode,
    feeCurrencyCode: ethereumUnsignedTx.feeCurrencyCode,
    feeCurrencyDecimal: ethereumUnsignedTx.feeCurrencyDecimal,
    blockchain: ethereumUnsignedTx.blockchain,
    metadata,
    baseFeePerGas: ethereumUnsignedTx.baseFeePerGas,
    l1GasFee: ethereumUnsignedTx.l1GasFee,
  });

  if (unsignedTxResult.kind === 'Success') {
    return {
      kind: 'Success',
      transaction,
      wallet,
      err: undefined,
    };
  }

  return {
    kind: 'Failure',
    wallet,
    err: EthereumError.unableToEstimateGas,
  };
}
