import { buildCreateAccountCalldata } from 'cb-wallet-data/scw/util/userOperation';
import { Address, concat, Hex } from 'viem';

type GetUserOpInitCodeParams = {
  owners: Hex[];
  index: bigint;
  accountFactoryAddress: Address;
};

export function getUserOpInitCode({
  owners,
  index,
  accountFactoryAddress,
}: GetUserOpInitCodeParams): Hex {
  const encodedFunctionData = buildCreateAccountCalldata({ owners, nonce: index });
  return concat([accountFactoryAddress, encodedFunctionData]);
}
