/* eslint-disable max-params, @typescript-eslint/default-param-last */
import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { PendingEthTxState } from 'cb-wallet-data/stores/Transactions/models/PendingEthTxState';
import { TxFlowOrType, TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataValue,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { TxSubmissionType } from 'cb-wallet-data/stores/Transactions/models/TxSubmissionType';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 } from 'uuid';

import { EthereumUnsignedTx } from './EthereumUnsignedTx';

/**
 * Represents an unsigned ethereum transaction ie. erc20, ether transfers, etc
 *
 * @property toAddress The contract address or EOA this transaction is interacting with. In the case of a contract function invocation, `toAddress` is the address of the smart contract that contains
 * the function being invoked. In the case of a simple transfer of the base asset, `toAddress` is the address of the recipient of the funds.
 * @property fromAddress The address the transaction is being sent from
 * @property walletIndex from address HD index
 * @property weiValue The amount of ether (in wei) being sent with the transaction. Corresponds to 'value' in standard
 * eth tx. Only should be non-zero if we are transferring ETH to another account
 * @property erc20Value The ERC20 transfer amount or null if not an ERC20
 * @property data The transaction data. If a function is being invoked on a contract, then this field will contain the encoded name of the function as well as encodings of each parameter being passed to the function
 * @property maxFeePerGas The max gas price in wei
 * @property maxPriorityFeePerGas The max priority fee in wei (used to tip miners)
 * @property gasLimit The gas limit. the maximum amount of gas that can be used in the execution of this transaction.
 * @property chainId The chain ID of the transaction
 * @property currencyCode The currency code of the transaction
 * @property blockchain Blockchain that for this transaction
 * @property transferValue The transfer value for non-ERC20 txs will always be the wei value, ERC20 txs uses erc20Value
 */
export class EthereumUnsigned1559Tx implements EthereumUnsignedTx {
  constructor(
    readonly toAddress: string,
    readonly toDomain: string | undefined,
    readonly fromAddress: string,
    readonly fromDomain: string | undefined,
    readonly walletIndex: bigint,
    readonly nonce: bigint | undefined,
    readonly weiValue: bigint,
    readonly erc20Value: bigint | undefined,
    readonly data: Buffer,
    readonly maxFeePerGas: bigint,
    readonly maxPriorityFeePerGas: bigint,
    readonly gasLimit: bigint,
    readonly network: Network,
    readonly currencyCode: CurrencyCode,
    readonly blockchain: Blockchain,
    readonly transferValue: bigint,
    readonly feeCurrencyCode: CurrencyCode,
    readonly feeCurrencyDecimal: bigint,
    readonly recipientAddress: string,
    readonly recipientDomain: string | undefined,
    readonly metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue> = new Map(),
    readonly baseFeePerGas: bigint,
    readonly l1GasFee: bigint | undefined,
  ) {}

  get estimatedFee(): bigint {
    throw EthereumError.unsupportedAccessToEstimatedFeeOn1559Tx;
  }

  /**
   *
   * @param baseFeePerGas base fee from a 1559 tx
   * @param maxPriorityFeePerGas max from 1559 tx
   * @param gasLimit gas limit from 1559 tx
   * @param l1GasFee L1 gas fee charged on L2s like OP and Base
   * @returns bigint and the lowerBoundFee fee for a 1559 tx
   */
  get lowerBoundFee(): bigint {
    return (this.baseFeePerGas + this.maxPriorityFeePerGas) * this.gasLimit + (this.l1GasFee ?? 0n);
  }

  /**
   *
   * @param maxFeePerGas maxFeePerGas from 1559 tx
   * @param gasLimit gas limit from 1559 tx
   * @param l1GasFee L1 gas fee charged on L2s like OP and Base
   * @returns bigint and the upperBoundFee for a 1559 tx
   */
  get upperBoundFee(): bigint {
    return this.maxFeePerGas * BigInt(this.gasLimit) + (this.l1GasFee ?? 0n);
  }

  /**
   * Custom constructor that generates an ether transaction
   */
  static createEtherTx({
    fromAddress,
    fromDomain,
    walletIndex,
    toAddress,
    toDomain,
    nonce,
    weiValue,
    data,
    maxFeePerGas,
    maxPriorityFeePerGas,
    gasLimit,
    network,
    currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    blockchain,
    metadata,
    baseFeePerGas,
    l1GasFee,
  }: CreateEth1559TxArgs): EthereumUnsigned1559Tx {
    return new EthereumUnsigned1559Tx(
      toAddress,
      toDomain,
      fromAddress,
      fromDomain,
      walletIndex,
      nonce,
      weiValue,
      undefined,
      data,
      maxFeePerGas,
      maxPriorityFeePerGas,
      gasLimit,
      network,
      currencyCode,
      blockchain,
      weiValue,
      feeCurrencyCode,
      feeCurrencyDecimal,
      toAddress ?? '',
      toDomain,
      metadata,
      baseFeePerGas,
      l1GasFee,
    );
  }

  /**
   * Custom constructor that generates an ERC20 transaction
   */
  static createERC20Tx({
    fromAddress,
    fromDomain,
    walletIndex,
    contractAddress,
    contractDomain,
    recipientAddress,
    recipientDomain,
    nonce,
    erc20Value,
    data,
    maxFeePerGas,
    maxPriorityFeePerGas,
    gasLimit,
    network,
    currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    blockchain,
    metadata = new Map(),
    baseFeePerGas,
    l1GasFee,
  }: CreateErc20Tx1559Args): EthereumUnsigned1559Tx {
    return new EthereumUnsigned1559Tx(
      contractAddress,
      contractDomain,
      fromAddress,
      fromDomain,
      walletIndex,
      nonce,
      0n,
      erc20Value,
      data,
      maxFeePerGas,
      maxPriorityFeePerGas,
      gasLimit,
      network,
      currencyCode,
      blockchain,
      erc20Value,
      feeCurrencyCode,
      feeCurrencyDecimal,
      recipientAddress,
      recipientDomain,
      metadata,
      baseFeePerGas,
      l1GasFee,
    );
  }

  asTransaction(
    signedTxHash: string,
    nonce: bigint | undefined,
    wallet: Wallet,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadataMap?: Map<TxOrUserOpMetadataKey, any>,
    txSubmissionType: TxSubmissionType = 'original',
    type?: TxFlowOrType,
  ): TxOrUserOp {
    return new TxOrUserOp({
      id: v4(),
      createdAt: new Date(),
      blockchain: this.blockchain,
      currencyCode: this.currencyCode,
      feeCurrencyCode: this.feeCurrencyCode,
      feeCurrencyDecimal: this.feeCurrencyDecimal,
      toAddress: this.recipientAddress,
      toDomain: this.recipientDomain,
      fromAddress: this.fromAddress,
      fromDomain: this.fromDomain,
      amount: this.transferValue,
      fee: this.upperBoundFee,
      state: TxState.PENDING,
      metadata: new TxOrUserOpMetadata(metadataMap),
      network: this.network,
      txOrUserOpHash: signedTxHash,
      userOpHash: undefined,
      accountId: wallet.accountId,
      walletIndex: wallet.selectedIndex ?? 0n,
      txHash: signedTxHash,
      isSent: true,
      contractAddress: wallet.contractAddress,
      tokenName: wallet.displayName,
      tokenDecimal: wallet.decimals,
      walletId: wallet.id,
      nonce,
      pendingEthTxData: new PendingEthTxState(
        this.baseFeePerGas,
        this.data,
        this.gasLimit,
        this.maxFeePerGas,
        this.maxPriorityFeePerGas,
        txSubmissionType,
        this.l1GasFee,
      ),
      type,
      transfers: [],
      deleted: false,
    });
  }
}

export type CreateErc20Tx1559Args = {
  fromAddress: string;
  fromDomain: string | undefined;
  walletIndex: bigint;
  contractAddress: string;
  contractDomain: string | undefined;
  recipientAddress: string;
  recipientDomain: string | undefined;
  nonce: bigint | undefined;
  erc20Value: bigint;
  data: Buffer;
  maxFeePerGas: bigint;
  maxPriorityFeePerGas: bigint;
  gasLimit: bigint;
  network: Network;
  currencyCode: CurrencyCode;
  feeCurrencyCode: CurrencyCode;
  feeCurrencyDecimal: bigint;
  blockchain: Blockchain;
  metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>;
  baseFeePerGas: bigint;
  l1GasFee: bigint | undefined;
};

export type CreateEth1559TxArgs = {
  fromAddress: string;
  fromDomain: string | undefined;
  walletIndex: bigint;
  toAddress: string;
  toDomain: string | undefined;
  nonce: bigint | undefined;
  weiValue: bigint;
  data: Buffer;
  maxFeePerGas: bigint;
  maxPriorityFeePerGas: bigint;
  gasLimit: bigint;
  network: Network;
  currencyCode: CurrencyCode;
  feeCurrencyCode: CurrencyCode;
  feeCurrencyDecimal: bigint;
  blockchain: Blockchain;
  metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>;
  baseFeePerGas: bigint;
  l1GasFee: bigint | undefined;
};
