import { GetBalanceReturnType } from '@wagmi/core';
import { PaymentMethod, PaymentMethods } from 'cb-wallet-analytics/scw';
import { logPaymasterReceived } from 'cb-wallet-analytics/scw/Paymaster';
import { AdjustableMinerFee1559Result } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/MinerFeeAPI';
import { MAGIC_SPEND_WEI_GAS_PADDING } from 'cb-wallet-data/MagicSpend/constants';
import {
  MagicSpendFlow,
  MagicSpendQuoteSignature,
  PasskeySigner,
} from 'cb-wallet-data/scw/api/types';
import {
  DEPLOYED_INIT_CODE,
  DUMMY_PAYMASTER_DATA,
  DUMMY_SIGNATURE,
  DUMMY_USEROP_GAS_DATA,
} from 'cb-wallet-data/scw/features/sign/constants';
import {
  Call,
  PaymasterService,
  StateOverrides,
  UserOp,
} from 'cb-wallet-data/scw/features/sign/transaction/types';
import { get1559GasPricesAndHandleError } from 'cb-wallet-data/scw/features/sign/transaction/utils/get1559GasPricesAndHandleError';
import { getCallDataForMaxSend } from 'cb-wallet-data/scw/features/sign/transaction/utils/getCallDataForMaxSend';
import {
  GetMagicSpendQuoteSignatureError,
  queryMagicSpendQuoteSignature,
} from 'cb-wallet-data/scw/features/sign/transaction/utils/getMagicSpendQuoteSignature';
import { getMagicSpendRequestAmount } from 'cb-wallet-data/scw/features/sign/transaction/utils/getMagicSpendRequestAmount';
import { queryPaymasterSignature } from 'cb-wallet-data/scw/features/sign/transaction/utils/getPaymasterSignature';
import { queryPaymasterStubData } from 'cb-wallet-data/scw/features/sign/transaction/utils/getPaymasterStubData';
import { getUserOpInitCode } from 'cb-wallet-data/scw/features/sign/transaction/utils/getUserOpInitCode';
import { queryEntryPointGetNonce } from 'cb-wallet-data/scw/features/sign/transaction/utils/queryEntryPointGetNonce';
import { queryEstimateMaxPriorityFeePerGas } from 'cb-wallet-data/scw/features/sign/transaction/utils/queryEstimateMaxPriorityFeePerGas';
import { entryPointAddress } from 'cb-wallet-data/scw/libs/wagmi/generated';
import { convertWeiToEthDecimal } from 'cb-wallet-data/utils/CurrencyUtil';
import { EstimateUserOperationGasError, EstimateUserOperationGasReturnType } from 'permissionless';
import {
  Address,
  EstimateMaxPriorityFeePerGasErrorType,
  GetBytecodeErrorType,
  GetBytecodeReturnType,
  Hex,
  maxUint256,
  ReadContractErrorType,
} from 'viem';

import {
  Get1559GasPricesError,
  PaymasterSignatureError,
  PaymasterStubError,
} from '../hooks/UseUnsignedUserOpErrors';

import { getEstimateUserOpGas } from './getEstimateUserOpGas';
import { getShouldMakePaymasterCall } from './getShouldMakePaymasterCall';
import { queryBytecode } from './queryBytecode';

type UserOpPreGasEstimation = {
  nonce: bigint;
  sender: `0x${string}`;
  initCode: `0x${string}`;
  callData: `0x${string}`;
  maxPriorityFeePerGas: bigint;
  maxFeePerGas: bigint;
  paymasterAndData: `0x${string}`;
  signature: `0x${string}`;
  preVerificationGas: bigint;
  verificationGasLimit: bigint;
  callGasLimit: bigint;
  additionalParameters: {
    baseFeePerGas: bigint;
  };
};

export type UnsignedUserOpWithUserOpGasEstimation = {
  nonce: bigint;
  sender: `0x${string}`;
  initCode: `0x${string}`;
  callData: `0x${string}`;
  maxPriorityFeePerGas: bigint;
  maxFeePerGas: bigint;
  signature: `0x${string}`;
  paymasterAndData: `0x${string}`;
  preVerificationGas: bigint;
  verificationGasLimit: bigint;
  callGasLimit: bigint;
  additionalParameters: {
    baseFeePerGas: bigint;
  };
};

export type BaseUnsignedUserOpParams = Pick<UserOp, 'sender'> & {
  calls: Call[];
  callData: Hex;
  paymasterService?: PaymasterService;
  // never used by dapps. for internal use only. intended for recovery flow to hardcode to 0
  maxFeePerGasOverride?: bigint;
  // never used by dapps. for internal use only. intended for recovery flow to hardcode to 0
  maxPriorityFeePerGasOverride?: bigint;
  accountFactoryAddress: Address;
  initialPasskeySigner: PasskeySigner;
  isMaxSendApplicable?: boolean;
  isPaymasterApplicable?: boolean;
};

export type GenerateUnsignedUserOpParamsBase = {
  chainId: number;
  sender: Hex;
  nonceKey: bigint;
  paymentMethod: PaymentMethod;
  dappOrigin?: string;
  balances?: Record<number, GetBalanceReturnType>;
  scwUrl: string;
} & BaseUnsignedUserOpParams;

export type GenerateUnsignedUserOpReturnSuccessType = {
  nonce: bigint;
  sender: `0x${string}`;
  initCode: `0x${string}`;
  callData: `0x${string}`;
  maxPriorityFeePerGas: bigint;
  maxFeePerGas: bigint;
  paymasterInfo?: { paymasterAndData: `0x${string}` };
  paymasterAndData: `0x${string}`;
  preVerificationGas: bigint;
  verificationGasLimit: bigint;
  callGasLimit: bigint;
  additionalParameters: {
    baseFeePerGas: bigint;
  };
  sponsorName?: string;
  callDataForMaxSend?: `0x${string}`;
  magicSpendQuoteSignature?: MagicSpendQuoteSignature;
  magicSpendFlow?: MagicSpendFlow;
  // These are non-terminal errors that may have occurred but which shouldn't block the user op generation
  additionalErrorsForLogging: {
    paymasterError?: PaymasterSignatureError;
    paymasterStubError?: PaymasterStubError;
  };
};

export class GenerateUnsignedUserOpReturnError extends Error {
  errorData: GenerateUnsignedUserOpReturnErrorType;
  constructor(errorData: GenerateUnsignedUserOpReturnErrorType) {
    super('Error generating unsigned user op');
    this.errorData = errorData;
  }
}

type GenerateUnsignedUserOpReturnErrorType = {
  nonceError?: ReadContractErrorType;
  gasPriceError?: Get1559GasPricesError;
  byteCodeError?: GetBytecodeErrorType;
  userOpGasError?: EstimateUserOperationGasError;
  magicSpendQuoteSignatureError?: GetMagicSpendQuoteSignatureError;
  maxPriorityFeePerGasError?: EstimateMaxPriorityFeePerGasErrorType;
};

export async function generateUnsignedUserOpBase({
  chainId,
  sender,
  nonceKey,
  paymentMethod,
  calls,
  maxPriorityFeePerGasOverride,
  maxFeePerGasOverride,
  initialPasskeySigner,
  accountFactoryAddress,
  callData,
  paymasterService,
  dappOrigin,
  isPaymasterApplicable,
  balances,
  isMaxSendApplicable = false,
  scwUrl,
}: GenerateUnsignedUserOpParamsBase): Promise<GenerateUnsignedUserOpReturnSuccessType> {
  const shouldMakePaymasterCall = getShouldMakePaymasterCall({
    chainId,
    hasPaymasterURL: !!paymasterService?.paymasterURL,
  });

  const [nonceResult, gasPriceDataResult, maxPriorityFeePerGasOriginalResult, byteCodeResult] =
    await Promise.allSettled([
      queryEntryPointGetNonce({ chainId, sender, nonceKey }),
      get1559GasPricesAndHandleError({ chainId }),
      queryEstimateMaxPriorityFeePerGas({ chainId }),
      queryBytecode({ chainId, address: sender }),
    ]);

  const hadError = [
    nonceResult,
    gasPriceDataResult,
    maxPriorityFeePerGasOriginalResult,
    byteCodeResult,
  ].some((result) => result.status === 'rejected');

  if (hadError) {
    throw new GenerateUnsignedUserOpReturnError({
      nonceError: nonceResult.status === 'rejected' ? nonceResult.reason : undefined,
      gasPriceError:
        gasPriceDataResult.status === 'rejected' ? gasPriceDataResult.reason : undefined,
      maxPriorityFeePerGasError:
        maxPriorityFeePerGasOriginalResult.status === 'rejected'
          ? maxPriorityFeePerGasOriginalResult.reason
          : undefined,
      byteCodeError: byteCodeResult.status === 'rejected' ? byteCodeResult.reason : undefined,
    });
  }

  const nonce = (nonceResult as PromiseFulfilledResult<bigint>).value;
  const gasPriceData = (gasPriceDataResult as PromiseFulfilledResult<AdjustableMinerFee1559Result>)
    .value;
  const maxPriorityFeePerGasData = (
    maxPriorityFeePerGasOriginalResult as PromiseFulfilledResult<bigint>
  ).value;

  const byteCode = (byteCodeResult as PromiseFulfilledResult<GetBytecodeReturnType>).value;

  /**
   * The bundler(s) we use (stackup / alchemy) use the priority fee from maxPriorityFeePerGas
   * which ends up being higher than our internal gas price oracle (via /1559GasPrices)
   * so we need to adjust the maxFeePerGas to account using the maxPriorityFeePerGas returned
   * from eth_maxPriorityFeePerGas
   */
  const adjustedMaxFeePerGas =
    BigInt(gasPriceData.fastMaxFeePerGas) -
    BigInt(gasPriceData.normalPriorityFee) +
    maxPriorityFeePerGasData;

  const maxPriorityFeePerGas = maxPriorityFeePerGasOverride ?? maxPriorityFeePerGasData;
  const maxFeePerGas = maxFeePerGasOverride ?? adjustedMaxFeePerGas;
  const baseFeePerGas = BigInt(gasPriceData.baseFee);

  let initCode;
  if (byteCode) {
    initCode = DEPLOYED_INIT_CODE as Hex;
  } else {
    initCode = getUserOpInitCode({
      owners: [initialPasskeySigner?.passkeyPublicKey],
      index: 0n,
      accountFactoryAddress,
    });
  }

  let userOpForStubData;

  if (
    nonce === undefined ||
    maxFeePerGas === undefined ||
    maxPriorityFeePerGas === undefined ||
    !byteCode
  ) {
    userOpForStubData = undefined;
  } else {
    userOpForStubData = {
      nonce,
      sender,
      initCode,
      callData,
      maxPriorityFeePerGas,
      maxFeePerGas,
      // These are dummy values but required in the userOp types
      preVerificationGas: DUMMY_USEROP_GAS_DATA,
      verificationGasLimit: DUMMY_USEROP_GAS_DATA,
      callGasLimit: DUMMY_USEROP_GAS_DATA,
      signature: DUMMY_SIGNATURE,
    };
  }

  if (paymasterService?.paymasterURL && chainId) {
    logPaymasterReceived({
      paymasterURL: paymasterService.paymasterURL,
      chainId,
      isPaymasterEnabled: shouldMakePaymasterCall,
    });
  }

  // Get paymaster stub data
  let paymasterStubInfo: Awaited<ReturnType<typeof queryPaymasterStubData>> | undefined;
  let additionalErrorsForLogging = {};
  if (
    typeof userOpForStubData !== 'undefined' &&
    typeof dappOrigin !== 'undefined' &&
    Boolean(isPaymasterApplicable) &&
    shouldMakePaymasterCall
  ) {
    try {
      paymasterStubInfo = await queryPaymasterStubData({
        userOp: userOpForStubData,
        entryPoint: entryPointAddress,
        chainId,
        paymasterService,
        dappOrigin,
      });
    } catch (err: ErrorOrAny) {
      // Not a terminal error, we will log and continue
      additionalErrorsForLogging = {
        ...additionalErrorsForLogging,
        paymasterStubError: err,
      };
    }
  }

  const hasPaymaster = Boolean(paymasterStubInfo?.paymasterAndData);

  let magicSpendFlow: MagicSpendFlow | undefined;
  if (paymentMethod === PaymentMethods.COINBASE) {
    // when no value is associated with any call, use magic spend to pay for gas only
    if (calls.every((call) => call.value === 0n)) {
      // paymaster is not available, use magic spend to pay for gas only
      // else, don't use magic spend
      if (!hasPaymaster) {
        magicSpendFlow = MagicSpendFlow.GAS_ONLY;
      }
    } else if (hasPaymaster) {
      // when paymaster is available, use magic spend to transfer fund for expense only
      magicSpendFlow = MagicSpendFlow.VALUE_ONLY;
    } else {
      // when paymaster is not available, use magic spend to pay for gas and transfer fund for expense
      magicSpendFlow = MagicSpendFlow.GAS_AND_VALUE;
    }
  }

  const stubPaymasterAndData =
    magicSpendFlow === MagicSpendFlow.GAS_ONLY ||
    magicSpendFlow === MagicSpendFlow.GAS_AND_VALUE ||
    !paymasterStubInfo?.paymasterAndData
      ? DUMMY_PAYMASTER_DATA
      : (paymasterStubInfo.paymasterAndData as Hex);

  const userOpPreGasEstimation: UserOpPreGasEstimation = {
    nonce,
    sender,
    initCode,
    callData,
    maxPriorityFeePerGas,
    maxFeePerGas,
    paymasterAndData: stubPaymasterAndData,
    signature: DUMMY_SIGNATURE,
    // These are dummy values but required in the userOp types
    preVerificationGas: DUMMY_USEROP_GAS_DATA,
    verificationGasLimit: DUMMY_USEROP_GAS_DATA,
    callGasLimit: DUMMY_USEROP_GAS_DATA,
    additionalParameters: {
      baseFeePerGas,
    },
  };

  let userOpGasData: EstimateUserOperationGasReturnType;
  let stateOverrides: StateOverrides | undefined;
  switch (magicSpendFlow) {
    case MagicSpendFlow.VALUE_ONLY:
    case MagicSpendFlow.GAS_AND_VALUE:
      stateOverrides = {
        [sender]: { balance: maxUint256 },
      };
      break;

    case MagicSpendFlow.GAS_ONLY:
    default:
      break;
  }
  try {
    userOpGasData = await getEstimateUserOpGas({
      chainId,
      userOp: userOpPreGasEstimation,
      entryPointAddress,
      stateOverrides,
    });
  } catch (err: ErrorOrAny) {
    throw new GenerateUnsignedUserOpReturnError({
      userOpGasError: err,
    });
  }

  let unsignedUserOpWithUserOpGasEstimation: UnsignedUserOpWithUserOpGasEstimation | undefined;
  if (
    !userOpGasData ||
    nonce === undefined ||
    maxFeePerGas === undefined ||
    maxPriorityFeePerGas === undefined ||
    baseFeePerGas === undefined
  ) {
    unsignedUserOpWithUserOpGasEstimation = undefined;
  } else {
    unsignedUserOpWithUserOpGasEstimation = {
      nonce,
      sender,
      initCode,
      callData,
      maxPriorityFeePerGas,
      maxFeePerGas,
      signature: DUMMY_SIGNATURE,
      paymasterAndData: DUMMY_PAYMASTER_DATA,
      preVerificationGas: userOpGasData.preVerificationGas,
      verificationGasLimit: userOpGasData?.verificationGasLimit ?? 0n,
      callGasLimit: userOpGasData.callGasLimit,
      additionalParameters: {
        baseFeePerGas,
      },
    };
  }

  let magicSpendRequestAmount = 0n;

  let magicSpendQuoteSignature: MagicSpendQuoteSignature | undefined;

  // If we are using Coinbase as a payment method, we need the magic spend paymaster and data to be
  // available.
  if (magicSpendFlow) {
    magicSpendRequestAmount = getMagicSpendRequestAmount({
      calls,
      maxFeePerGas,
      gasEstimatesWithoutPaymaster: userOpGasData,
      magicSpendFlow,
    });
    if (magicSpendRequestAmount > 0n) {
      try {
        magicSpendQuoteSignature = await queryMagicSpendQuoteSignature({
          amount: convertWeiToEthDecimal(magicSpendRequestAmount),
          chainId,
          walletAddress: sender,
          sourceAsset: 'ETH',
          targetAsset: 'ETH',
          toAddressesInUserOps: calls.map((call) => call.target),
          scwUrl,
        });
      } catch (err: ErrorOrAny) {
        throw new GenerateUnsignedUserOpReturnError({
          magicSpendQuoteSignatureError: err,
        });
      }
    }
  }

  let paymasterInfo: { paymasterAndData: `0x${string}` } | undefined;
  if (
    typeof unsignedUserOpWithUserOpGasEstimation !== 'undefined' &&
    typeof dappOrigin !== 'undefined' &&
    (!magicSpendFlow || magicSpendFlow === MagicSpendFlow.VALUE_ONLY) &&
    Boolean(isPaymasterApplicable) &&
    shouldMakePaymasterCall
  ) {
    try {
      paymasterInfo = await queryPaymasterSignature({
        userOp: unsignedUserOpWithUserOpGasEstimation,
        entryPoint: entryPointAddress,
        chainId,
        paymasterService,
        dappOrigin,
      });
    } catch (err: ErrorOrAny) {
      // Not a terminal error, we will log and continue
      additionalErrorsForLogging = {
        ...additionalErrorsForLogging,
        paymasterError: err,
      };
    }
  }

  const sponsorName = paymasterStubInfo?.sponsor?.name;

  const callDataForMaxSend = getCallDataForMaxSend({
    balances,
    calls,
    chainId,
    isMaxSendApplicable,
    paymasterAndData: paymasterInfo?.paymasterAndData,
    paymasterService,
    paymentMethod,
    unsignedUserOpWithUserOpGasEstimation,
  });

  const paymasterAndData = paymasterInfo?.paymasterAndData ?? DUMMY_PAYMASTER_DATA;

  // If we are using magic spend to withdraw funds for a transaction that requires ETH, we need to
  // pad the call gas limit by 8000 to account for the withdrawGasExcess call.
  const magicSpendTransferGasLimit =
    magicSpendFlow === MagicSpendFlow.GAS_AND_VALUE || magicSpendFlow === MagicSpendFlow.VALUE_ONLY
      ? MAGIC_SPEND_WEI_GAS_PADDING
      : 0n;

  const callGasLimit = (userOpGasData?.callGasLimit ?? 0n) + magicSpendTransferGasLimit;

  let callDataForFinalUserOp: Hex;

  if (callDataForMaxSend) {
    callDataForFinalUserOp = callDataForMaxSend;
  } else {
    callDataForFinalUserOp = callData;
  }

  return {
    nonce,
    sender,
    initCode,
    callData: callDataForFinalUserOp,
    maxPriorityFeePerGas,
    maxFeePerGas,
    paymasterInfo,
    /**
     * Use paymaster if available, fallback to dummy data
     */
    paymasterAndData,
    preVerificationGas: userOpGasData?.preVerificationGas ?? 0n,
    verificationGasLimit: userOpGasData?.verificationGasLimit ?? 0n,
    callGasLimit,
    additionalParameters: {
      baseFeePerGas,
    },
    sponsorName,
    callDataForMaxSend,
    magicSpendQuoteSignature,
    magicSpendFlow,
    additionalErrorsForLogging,
  };
}
