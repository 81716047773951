import padStart from 'lodash/padStart';
import { strip0x } from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';
import { Address } from 'wallet-engine-signing/signing/ethereum/Address';

import { ERC20TransferParams } from './types';

export function encodeERC20Transfer(
  toAddress: string,
  contractAddress: string,
  value: bigint,
  skipChecksumValidationForToAddress = false,
): ERC20TransferParams {
  const toAddressValidityError = Address.getAddressValidationError(
    toAddress,
    skipChecksumValidationForToAddress,
  );
  if (toAddressValidityError) {
    throw toAddressValidityError;
  }

  if (!Address.isValid(contractAddress)) {
    throw Error(`Invalid "contractAddress" value passed. Given:${contractAddress}`);
  }

  return {
    toAddress: contractAddress,
    data: Buffer.from(
      [
        'a9059cbb',
        padStart(strip0x(toAddress), 64, '0'),
        padStart(value.toString(16), 64, '0'),
      ].join(''),
      'hex',
    ),
  };
}
