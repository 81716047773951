import { PublicKey, TransactionMessage, VersionedTransaction } from '@solana/web3.js';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

import { SolanaUnsignedTransaction } from '../models/SolanaUnsignedTransaction';
/**
 * Generate a Solana transaction for signing from an SolanaUnsignedTransaction
 * @param wallet
 * @param unsignedTx
 * @param recentBlockhash
 * @return a VersionedTransaction ready to be signed
 */
export function buildSolanaTransactionToSign(
  wallet: Wallet,
  unsignedTx: SolanaUnsignedTransaction,
  recentBlockhash: string,
): VersionedTransaction {
  const messageV0 = new TransactionMessage({
    payerKey: new PublicKey(wallet.primaryAddress),
    recentBlockhash,
    // TODO: should we allow this? May want to throw from this func if no instructions are defined
    instructions: unsignedTx.instructions ?? [],
  }).compileToV0Message();

  return new VersionedTransaction(messageV0);
}
