import {
  EthTxConfig1559,
  EthTxConfigLegacy,
  EthUserOpConfig,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/chainConfigs';
import { Account } from 'cb-wallet-data/stores/Accounts/models/Account';
import {
  GenerateUnsignedTransactionArgs,
  GenerateUnsignedTransactionResult,
} from 'cb-wallet-data/stores/Transactions/methods/generateUnsignedTransaction';

import { generateUnsigned1559Transaction } from './generateUnsigned1559Transaction';
import { generateUnsignedERC201559Transaction } from './generateUnsignedERC201559Transaction';
import { generateUnsignedLegacyERC20Transaction } from './generateUnsignedLegacyERC20Transaction';
import { generateUnsignedLegacyTransaction } from './generateUnsignedLegacyTransaction';
import { generateUnsignedUserOp } from './generateUnsignedUserOp';

export type EthUnsignedTxOrUserOpConfig = EthTxConfigLegacy | EthTxConfig1559 | EthUserOpConfig;

export async function generateUnsignedTransaction(
  unsignedTxOrUserOpArgs: GenerateUnsignedTransactionArgs,
): GenerateUnsignedTransactionResult {
  const { wallet } = unsignedTxOrUserOpArgs;

  // UTXO transactions not supported in this method
  if (unsignedTxOrUserOpArgs.txOrUserOpConfig.txType === 'UTXO') {
    throw new Error('UTXO transaction not supported here');
  }

  if (
    Account.isSCWAccount(unsignedTxOrUserOpArgs.wallet.accountId) &&
    unsignedTxOrUserOpArgs.txOrUserOpConfig.txType !== 'UserOp'
  ) {
    throw new Error('Got eoa config for an scw account');
  }

  if (unsignedTxOrUserOpArgs.txOrUserOpConfig.txType === 'UserOp') {
    return generateUnsignedUserOp(unsignedTxOrUserOpArgs);
  }

  // non-ERC20 transaction
  if (wallet.isBaseAsset && unsignedTxOrUserOpArgs.txOrUserOpConfig) {
    if (unsignedTxOrUserOpArgs.txOrUserOpConfig.txType === '1559') {
      return generateUnsigned1559Transaction(unsignedTxOrUserOpArgs);
    }
    if (unsignedTxOrUserOpArgs.txOrUserOpConfig.txType === 'Legacy') {
      return generateUnsignedLegacyTransaction(unsignedTxOrUserOpArgs);
    }
  }

  // ERC20 Transaction
  if (unsignedTxOrUserOpArgs.txOrUserOpConfig) {
    if (unsignedTxOrUserOpArgs.txOrUserOpConfig.txType === '1559') {
      return generateUnsignedERC201559Transaction(unsignedTxOrUserOpArgs);
    }
    if (unsignedTxOrUserOpArgs.txOrUserOpConfig.txType === 'Legacy') {
      return generateUnsignedLegacyERC20Transaction(unsignedTxOrUserOpArgs);
    }
  }

  const _exhaustiveCheck: never = unsignedTxOrUserOpArgs.txOrUserOpConfig;
  return _exhaustiveCheck;
}
