import { StateOverrides, UserOp } from 'cb-wallet-data/scw/features/sign/transaction/types';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { stringify } from 'cb-wallet-store/utils/serialization';
import { estimateUserOperationGas } from 'permissionless';
import { Address } from 'viem';
import type { PartialBy } from 'viem/types/utils';

import { adjustEstimatedUserOpGas } from './adjustEstimatedUserOpGas';

export type EstimateUserOpGasParams = {
  chainId: number;
  entryPointAddress: Address;
  userOp: PartialBy<UserOp, 'callGasLimit' | 'preVerificationGas' | 'verificationGasLimit'>;
  stateOverrides?: StateOverrides;
};
export function getEstimateUserOpGasQueryKey({
  userOp,
}: {
  userOp?: PartialBy<UserOp, 'callGasLimit' | 'preVerificationGas' | 'verificationGasLimit'>;
}) {
  return ['estimateUserOperationGas', stringify(userOp ?? {})];
}

export async function getEstimateUserOpGas({
  chainId,
  entryPointAddress,
  userOp,
  stateOverrides,
}: EstimateUserOpGasParams) {
  const client = getConfig().getClient({ chainId });

  const data = await estimateUserOperationGas(
    client,
    {
      userOperation: userOp,
      entryPoint: entryPointAddress,
    },
    stateOverrides,
  );

  return adjustEstimatedUserOpGas(data);
}
