import { cbReportError } from 'cb-wallet-data/errors/reportError';
import { queryClient } from 'cb-wallet-data/QueryProvider';
import {
  MagicSpendQuoteSignature,
  MagicSpendQuoteSignatureResponse,
} from 'cb-wallet-data/scw/api/types';
import { fetchWithBodyJSON } from 'cb-wallet-http/fetchJSON';
import { HTTPError } from 'cb-wallet-http/HTTPError';
import { Address } from 'viem';

export class GetMagicSpendQuoteSignatureError extends HTTPError {
  constructor(
    readonly status: number,
    readonly code: string,
    readonly message: string,
    readonly description?: string,
    readonly errorDetail?: Record<string, string | number | boolean>,
  ) {
    super(status, code, message, description, errorDetail);
    this.name = 'GetMagicSpendQuoteSignatureError';
  }
}

export type GetMagicSpendQuoteSignatureParams = {
  amount: string;
  chainId: number;
  walletAddress: Address;
  sourceAsset: string;
  targetAsset: string;
  toAddressesInUserOps: Address[];
  metadata?: Record<string, string>;
};

export function getMagicSpendQuoteQueryKey({
  amount,
  chainId,
  walletAddress,
  sourceAsset,
  targetAsset,
  toAddressesInUserOps,
}: GetMagicSpendQuoteSignatureParams) {
  return [
    'magicSpendQuoteSignature',
    { amount, chainId, walletAddress, sourceAsset, targetAsset, toAddressesInUserOps },
  ];
}

export async function queryMagicSpendQuoteSignature({
  amount,
  chainId,
  walletAddress,
  sourceAsset,
  targetAsset,
  toAddressesInUserOps,
  scwUrl,
}: GetMagicSpendQuoteSignatureParams & {
  scwUrl: string;
}): Promise<MagicSpendQuoteSignature> {
  const result = await queryClient.fetchQuery({
    queryKey: getMagicSpendQuoteQueryKey({
      amount,
      chainId,
      walletAddress,
      sourceAsset,
      targetAsset,
      toAddressesInUserOps,
    }),
    queryFn: async () =>
      getMagicSpendQuoteSignature({
        amount,
        chainId,
        walletAddress,
        sourceAsset,
        targetAsset,
        toAddressesInUserOps,
        scwUrl,
      }),
  });

  return selectMagicSpendQuoteSignature(result);
}

export async function getMagicSpendQuoteSignature({
  amount,
  chainId,
  walletAddress,
  sourceAsset,
  targetAsset,
  toAddressesInUserOps,
  scwUrl,
}: GetMagicSpendQuoteSignatureParams & {
  scwUrl: string;
}): Promise<MagicSpendQuoteSignatureResponse> {
  try {
    return (await fetchWithBodyJSON(
      `${scwUrl}/api/v3/coinbase.dex.RetailDexService/GetMagicSpendSignatureQuoteV2`,
      // https://sourcegraph.cbhq.net/github.cbhq.net/retail/dex@master/-/blob/protos/coinbase/dex/get_magic_spend_signature_quote_v2.proto?L10-20
      {
        // eslint-disable-next-line camelcase
        source_asset: sourceAsset,
        // eslint-disable-next-line camelcase
        target_asset: targetAsset,
        amount: amount.toString(),
        // eslint-disable-next-line camelcase
        amount_reference: 'source',
        // eslint-disable-next-line camelcase
        wallet_address: walletAddress,
        // eslint-disable-next-line camelcase
        chain_id: chainId.toString(),
        metadata: {
          client: 'cb-wallet-scw',
        },
        // eslint-disable-next-line camelcase
        to_addresses_in_user_ops: toAddressesInUserOps,
      },
      { isThirdParty: true, authenticated: false, method: 'POST' },
    )) as MagicSpendQuoteSignatureResponse;
  } catch (e: ErrorOrAny) {
    const error = new GetMagicSpendQuoteSignatureError(
      e.status,
      e.code,
      e.message,
      e.description,
      e.errorDetail,
    );
    cbReportError({
      error,
      context: 'scw-error',
      severity: 'error',
      isHandled: true,
    });
    throw error;
  }
}

export function selectMagicSpendQuoteSignature(
  data: MagicSpendQuoteSignatureResponse,
): MagicSpendQuoteSignature {
  return {
    ...data,
    expiresAt: data.quoteExpiryAt,
    quoteExpiryAt: undefined,
    mfaRequired: data.mfaRequired ?? false,
  } as MagicSpendQuoteSignature;
}
