import { SolanaWalletConfiguration as configuration } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SolanaUnsignedTransaction } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaUnsignedTransaction';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import {
  SignAndSubmitArguments,
  SignAndSubmitReturn,
} from 'cb-wallet-data/stores/Transactions/methods/signAndSubmitTransaction';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { SOLANA_RPC_URL } from 'cb-wallet-env/env';
import { v4 as uuidv4 } from 'uuid';
import { getLatestBlockhash } from 'wallet-engine-signing/history/Solana/RPC';

import { saveTransaction } from '../database';
import { SolanaError } from '../errors';
import { SolanaNetworkMap } from '../models/SolanaChain';
import { SolanaSignedTx } from '../models/SolanaSignedTx';
import { buildSolanaTransactionToSign } from '../utils/buildSolanaTransactionToSign';
import { partialSignAndSubmitSolanaTransaction } from '../utils/partialSignAndSubmitSolanaTransaction';
import { signAndSubmitSolanaTransaction } from '../utils/signAndSubmitSolanaTransaction';

export async function signAndSubmitTransaction({
  unsignedTxOrUserOp,
  wallet,
  onSubmit,
  txSubmissionType: _txSubmissionType,
  skipSubmit,
}: SignAndSubmitArguments): SignAndSubmitReturn {
  try {
    const unsignedSolTx = unsignedTxOrUserOp as SolanaUnsignedTransaction;

    let signedTransactionBuffer: Buffer;
    let txHash: string | undefined;

    const { blockhash: recentBlockhash, lastValidBlockHeight } = await getLatestBlockhash({
      rpcUrl: SOLANA_RPC_URL,
    });

    if (unsignedSolTx.nativeDappTransaction) {
      const { data: signedTx, hash: txHashRet } = await partialSignAndSubmitSolanaTransaction(
        unsignedSolTx.nativeDappTransaction,
        wallet.accountId,
        recentBlockhash,
        skipSubmit ?? false,
        unsignedSolTx.submissionParams,
        wallet.selectedIndex,
      );
      signedTransactionBuffer = signedTx;
      txHash = txHashRet;
    } else {
      const transactionToSign = buildSolanaTransactionToSign(
        wallet,
        unsignedSolTx,
        recentBlockhash,
      );
      const { data: signedTx, hash: txHashRet } = await signAndSubmitSolanaTransaction(
        transactionToSign,
        wallet.accountId,
        skipSubmit ?? false,
        unsignedSolTx.submissionParams,
        wallet.selectedIndex,
      );
      signedTransactionBuffer = signedTx;
      txHash = txHashRet;
    }

    if (skipSubmit) {
      return new SolanaSignedTx(
        uuidv4(),
        wallet.primaryAddress,
        unsignedSolTx.recipientAddress,
        BigInt(
          unsignedSolTx.network.asChain()?.chainId ??
            SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId,
        ),
        signedTransactionBuffer,
        '',
        unsignedSolTx.transferValue,
        unsignedSolTx.blockchain,
        configuration.currencyCode,
        TxState.PENDING,
        recentBlockhash,
        lastValidBlockHeight,
        0n,
      );
    }

    if (txHash) {
      const signedTx = new SolanaSignedTx(
        uuidv4(),
        wallet.primaryAddress,
        unsignedSolTx.recipientAddress,
        BigInt(
          unsignedSolTx.network.asChain()?.chainId ??
            SolanaNetworkMap.whitelisted.SOLANA_MAINNET.chainId,
        ),
        signedTransactionBuffer,
        txHash,
        unsignedSolTx.transferValue,
        unsignedSolTx.blockchain,
        configuration.currencyCode,
        TxState.PENDING,
        recentBlockhash,
        lastValidBlockHeight,
        0n,
      );
      await saveTransaction(signedTx);

      /**
       * We do not want Solana dapp transactions made with the sendTransaction
       * method to enter the "pending" state in the transaction history view
       */
      if (unsignedSolTx.shouldSavePendingTransaction) {
        onSubmit(signedTx);
      }

      return signedTx;
    }
    throw SolanaError.unableToSubmitTransaction;
  } catch (error: ErrorOrAny) {
    cbReportError({
      error,
      context: 'solana-error',
      severity: 'error',
      isHandled: false,
    });
    throw error;
  }
}
