import { SendOptions, Transaction, VersionedTransaction } from '@solana/web3.js';
import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import { TransactionError } from 'cb-wallet-data/stores/Transactions/TransactionError';

import { solanaAddressDerivationPath } from '../config';

/**
 * Partially sign transaction using mnemonic, default to index 0
 * @param solanaTx
 * @param accountId
 * @param recentBlockhash
 * @param skipSubmit
 * @param sendOptions
 * @param walletIndex
 * @return a Buffer of signed transaction
 */
export async function partialSignAndSubmitSolanaTransaction(
  solanaTx: Transaction | VersionedTransaction,
  accountId: string,
  recentBlockhash: string,
  skipSubmit: boolean,
  sendOptions?: SendOptions,
  walletIndex?: bigint,
): Promise<{ data: Buffer; hash?: string }> {
  const derivationPath = solanaAddressDerivationPath(walletIndex || 0n);

  if (solanaTx instanceof Transaction) {
    const partialSignAndSubmitSolanaTx = getSigningOrDerivationMethodForAccount(
      accountId,
      'partialSignAndSubmitSolanaTransaction',
    );

    return partialSignAndSubmitSolanaTx({
      accountId,
      derivationPath,
      solanaTx,
      skipSubmit,
      sendOptions,
    });
  }

  if (solanaTx instanceof VersionedTransaction) {
    // The VersionedTransaction class does not have a partialSign method. The regular sign() will work as expected
    // because it does not destroy existing signatures the way the legacy Transaction.sign() does
    const signAndSubmitSolanaVersionedTx = getSigningOrDerivationMethodForAccount(
      accountId,
      'signAndSubmitSolanaVersionedTransaction',
    );

    return signAndSubmitSolanaVersionedTx({
      accountId,
      derivationPath,
      solanaTx,
      skipSubmit,
      sendOptions,
    });
  }

  throw TransactionError.UnknownTransactionType;
}
