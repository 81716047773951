import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { v4 as uuidv4 } from 'uuid';
import { prepend0x } from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';

import { EthereumUnsignedUserOp } from '../models/EthereumUnsignedUserOp';

/**
 * NOTE: resubmit is not a requirement since user ops get submitted to a bundler vs to a mempool directly.
 * We're making an assumption here that resubmit will not be needed. For this reason we are not inserting
 * the signed user into the ethereum_signed_tx table and we're creating an object that conforms to the
 * SignedTx interface. This is used for generating the TxOrUserOp object after sign and submit but is not
 * stored for reuse. In the future if we determine a need to resubmit user ops we will want to revisit this.
 * Likely by renaming EthereumSignTx to EthereumSignedTxOrUserOp. Updating the `ethereum_signed_tx` table
 * to be more inclusive and updating the resubmit pending transactions functions to account for userops
 * properly
 */
export class EthereumSignedUserOp implements SignedTx {
  // eslint-disable-next-line max-params
  constructor(
    readonly id: string,
    readonly fromAddress: string,
    readonly toAddress: string | undefined,
    readonly nonce: bigint,
    readonly chainId: bigint,
    readonly signedData: Buffer,
    readonly txHash: string,
    readonly callDataValue: bigint,
    readonly blockchain: Blockchain,
    readonly currencyCode: CurrencyCode,
    readonly state: TxState,
    readonly notFoundCount: bigint,
  ) {}
}

/**
 * @param userOp The user op to sign
 * @param accountId The accountId to sign the user op with
 * @param skipSubmit `true` when the caller wants us to sign the user op without submitting. `false` otherwise.
 *
 * @return The signed user op
 */
export async function signAndSubmitUserOp({
  userOp,
  accountId,
  skipSubmit = false,
  skipPrepare = false,
}: {
  userOp: EthereumUnsignedUserOp;
  accountId: string;
  skipSubmit?: boolean;
  skipPrepare?: boolean;
}): Promise<EthereumSignedUserOp> {
  const ethereumChain = userOp.network.asChain();
  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(userOp.network);
  }

  const signAndSubmitETHUserOp = getSigningOrDerivationMethodForAccount(
    accountId,
    'signAndSubmitETHUserOp',
  );

  const signedUserOp = await signAndSubmitETHUserOp({
    accountId,
    skipSubmit,
    skipPrepare,
    unsignedTx: {
      sender: userOp.fromAddress,
      callData: userOp.callData,
      callGasLimit: userOp.callGasLimit,
      nonce: userOp.nonce,
      initCode: userOp.initCode,
      verificationGasLimit: userOp.verificationGasLimit,
      preVerificationGas: userOp.preVerificationGas,
      maxFeePerGas: userOp.maxFeePerGas,
      maxPriorityFeePerGas: userOp.maxPriorityFeePerGas,
      paymasterAndData: userOp.paymasterAndData,
      chainId: BigInt(ethereumChain.chainId),
    },
  });

  return new EthereumSignedUserOp(
    uuidv4(),
    userOp.fromAddress,
    userOp.recipientAddress,
    userOp.nonce,
    BigInt(ethereumChain.chainId),
    signedUserOp.data,
    prepend0x(signedUserOp.hash.toString('hex')),
    userOp.calls.map((call) => call.value).reduce((acc, val) => acc + val, 0n),
    userOp.blockchain,
    userOp.currencyCode,
    TxState.PENDING,
    0n,
  );
}
