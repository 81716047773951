import { decodeFunctionData } from 'viem';

type DecodedERC20Transfer = {
  toAddress: string;
  value: bigint;
};

const transferAbi = [
  {
    inputs: [
      { name: 'address', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', type: 'uint256' }],
    type: 'function',
  },
] as const;

export function decodeERC20Transfer(data: `0x${string}`): DecodedERC20Transfer | undefined {
  const { functionName, args } = decodeFunctionData({
    abi: transferAbi,
    data,
  });
  if (functionName !== 'transfer' || args?.length !== 2) {
    return undefined;
  }

  return {
    toAddress: args[0] as string,
    value: BigInt(args[1] as string),
  };
}
