import { estimateMaxPriorityFeePerGas } from '@wagmi/core';
import { queryClient } from 'cb-wallet-data/QueryProvider';
import { USER_OP_STALE_TIME_FOR_USER_OP_GENERATION } from 'cb-wallet-data/scw/features/sign/constants';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { bigIntMax } from 'cb-wallet-data/utils/BigInt+Core';
import { convertGweiToWei } from 'cb-wallet-data/utils/CurrencyUtil';
import { bsc } from 'viem/chains';

const MINIMUM_BSC_PRIORITY_FEE_GWEI = BigInt(3);
export const MINIMUM_BSC_PRIORITY_FEE_WEI = convertGweiToWei(
  MINIMUM_BSC_PRIORITY_FEE_GWEI.toString(),
);

export function selectEstimateMaxPriorityFeePerGasData({
  data,
  chainId,
}: {
  data: bigint;
  chainId: number;
}) {
  /**
   * BSC specifically requires 3 gwei for the max priority fee per gas in order to avoid stuck transactions,
   * this is enforced by the bundler (stackup). We're solving this on the frontend because a change to our
   * backend APIs would affect users across all of our wallet products, not just SCW.
   */
  if (chainId === bsc.id) {
    return bigIntMax(data, MINIMUM_BSC_PRIORITY_FEE_WEI);
  }

  return data;
}

export type QueryEstimateMaxPriorityFeePerGasParams = {
  chainId: number;
};

export async function queryEstimateMaxPriorityFeePerGas({
  chainId,
}: QueryEstimateMaxPriorityFeePerGasParams): Promise<bigint> {
  const data = await queryClient.fetchQuery(
    ['estimateMaxPriorityFeePerGas', chainId],
    async () => {
      return estimateMaxPriorityFeePerGas(getConfig(), { chainId });
    },
    { staleTime: USER_OP_STALE_TIME_FOR_USER_OP_GENERATION },
  );

  return selectEstimateMaxPriorityFeePerGasData({ data, chainId });
}
