import { AnalyticsEventProperties, logDataEvent, logMetric } from 'cb-wallet-analytics/utils/log';
import { WalletMetricTags } from 'cb-wallet-analytics/utils/types/metricTags';
import { MetricType, ValidEventData } from '@cbhq/client-analytics';

import { getErrorMessage } from './getErrorMessage';

export type LoggedArgument<T> = {
  start: string;
  success: string;
  fail: string;
  data: AnalyticsEventProperties<ValidEventData>;
  metricTags?: WalletMetricTags;
  addSuccessDataToLog?: (successData: T) => Partial<T>;
};

/**
 * Enhances an async operation with event logging
 *
 * @param operation An async operation to wrap with event logging
 * @returns The result of awaiting the operation
 */
export async function withEventLogging<T>(
  operation: () => Promise<T>,
  { start, success, fail, data, metricTags, addSuccessDataToLog }: LoggedArgument<T>,
): Promise<T> {
  logDataEvent(start, data);
  logMetric({
    metricName: start,
    metricType: MetricType.count,
    value: 1,
    tags: metricTags,
  });
  try {
    const result = await operation();
    const successData = addSuccessDataToLog?.(result) ?? {};
    logDataEvent(success, {
      ...data,
      ...successData,
    });
    logMetric({
      metricName: success,
      metricType: MetricType.count,
      value: 1,
      tags: metricTags,
    });
    return result;
  } catch (error) {
    logDataEvent(fail, {
      ...data,
      errorMessage: getErrorMessage(error),
    });
    logMetric({
      metricName: fail,
      metricType: MetricType.count,
      value: 1,
      tags: metricTags,
    });
    throw error;
  }
}
