import {
  EthereumWalletConfiguration,
  TxOrUserOpMetadataKey_txSource,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { EthereumUnsigned1559Tx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumUnsigned1559Tx';
import { EthTxConfig1559 } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/chainConfigs';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import {
  GenerateUnsignedTransactionArgs,
  GenerateUnsignedTransactionResult,
} from 'cb-wallet-data/stores/Transactions/methods/generateUnsignedTransaction';

import { get1559GasAndTxValue } from './utils/gas';

/**
 * Generate an unsigned 1559 transaction
 */
export async function generateUnsigned1559Transaction({
  wallet,
  recipientAddress,
  metadata,
  transactionAmount,
  txOrUserOpConfig,
  isSponsoredTx,
}: GenerateUnsignedTransactionArgs): GenerateUnsignedTransactionResult {
  const {
    selectedBaseFeePerGas,
    selectedMaxFeePerGas,
    selectedMaxPriorityFeePerGas,
    selectedGasLimit,
    dataBuffer,
    nonce,
  } = txOrUserOpConfig as EthTxConfig1559;

  const {
    primaryAddress: fromAddress,
    network,
    currencyCode,
    blockchain,
    selectedIndex: walletIndex,
  } = wallet;

  const ethereumChain = network.asChain() as EthereumChain;

  if (!ethereumChain) throw NetworkError.invalidNetwork(network);

  const txSource = metadata.get(TxOrUserOpMetadataKey_txSource)?.toLocaleString() ?? '';

  const data = dataBuffer ?? Buffer.from(new Uint8Array(0));

  // throw error only when it's undefined explicitly.
  // we don't want `0` walletIndex to trigger error.
  if (walletIndex === undefined) {
    throw EthereumError.unableToFindWallet;
  }

  const feeCurrencyCode = EthereumWalletConfiguration.currencyCodeForNetwork(network);
  const feeCurrencyDecimal = EthereumWalletConfiguration.feeDecimalForNetwork(network);

  const {
    weiValue,
    baseFeePerGas,
    maxFeePerGas,
    maxPriorityFeePerGas,
    gasLimit,
    gasEstimationStatus,
    l1GasFee,
  } = await get1559GasAndTxValue({
    transactionAmount,
    data,
    wallet,
    recipientAddress,
    selectedBaseFeePerGas,
    selectedMaxFeePerGas,
    selectedMaxPriorityFeePerGas,
    selectedGasLimit,
    txSource,
    nonce,
    isSponsoredTx,
  });

  const [fromDomain, recipientDomain] = await fetchDomains([fromAddress, recipientAddress]);

  const transaction = EthereumUnsigned1559Tx.createEtherTx({
    fromAddress,
    fromDomain,
    walletIndex,
    toAddress: recipientAddress,
    toDomain: recipientDomain,
    nonce,
    weiValue,
    data,
    maxFeePerGas,
    maxPriorityFeePerGas,
    gasLimit,
    network,
    currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    blockchain,
    metadata,
    baseFeePerGas,
    l1GasFee,
  });

  if (gasEstimationStatus === 'Success') {
    return {
      kind: 'Success',
      transaction,
      wallet,
      err: undefined,
    };
  }

  return {
    kind: 'Failure',
    wallet,
    transaction,
    err: EthereumError.unableToEstimateGas,
  };
}
