export class TransactionError extends Error {
  constructor(readonly name: string, readonly message: string) {
    super(message);
  }

  /**
   * Thrown when the repository is passed a transaction of unknown type
   */
  static UnknownTransactionType = new TransactionError(
    'UnknownTransactionType',
    'Unknown transaction type',
  );

  /**
   * Thrown when unable to hex encode string
   */
  static UnableToHexEncode = new TransactionError('UnableToHexEncode', 'Unable to hex encode');

  /** Thrown when sponsored send fails */
  static SponsoredSendError = new TransactionError(
    'SponsoredSendError',
    'Error attempting to send sponsored transaction',
  );

  /** Thrown when gasles swap fails */
  static GaslessSwapError = new TransactionError(
    'GaslessSwapError',
    'Error attempting to submit gasless swap transaction',
  );
}
