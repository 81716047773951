import { baseSepolia } from '@wagmi/core/chains';
import {
  logPaymasterStubDataFailure,
  logPaymasterStubDataStart,
  logPaymasterStubDataSuccess,
} from 'cb-wallet-analytics/scw/Paymaster';
import { getEthereumRPCURL } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { queryClient } from 'cb-wallet-data/QueryProvider';
import { USER_OP_STALE_TIME_FOR_USER_OP_GENERATION } from 'cb-wallet-data/scw/features/sign/constants';
import { PaymasterStubError } from 'cb-wallet-data/scw/features/sign/transaction/hooks/UseUnsignedUserOpErrors';
import { PaymasterService, UserOp } from 'cb-wallet-data/scw/features/sign/transaction/types';
import { BASE_SEPOLIA_PAYMASTER } from 'cb-wallet-data/scw/features/sign/transaction/utils/getPaymasterSignature';
import { CB_WALLET_API_URL } from 'cb-wallet-env/env';
import { stringify } from 'cb-wallet-store/utils/serialization';
import { Address, numberToHex, toHex } from 'viem';
import { callEthereumJSONRPC } from 'wallet-engine-signing/blockchains/Ethereum/RPC';

// we proxy all paymaster calls through our backend to circumvent popup traffic protections
// since traffic must be to whitelisted sites only
export const PAYMASTER_BACKEND_PROXY = `${CB_WALLET_API_URL}/rpc/v3/scw/sdk-proxy`;

type GetPaymasterStubDataParams = {
  userOp: Omit<UserOp, 'paymasterAndData'>;
  entryPoint: Address;
  paymasterService?: PaymasterService;
  chainId: number;
  dappOrigin: string;
};

type GetQueryKeyParams = {
  userOp?: Omit<UserOp, 'paymasterAndData'>;
  entryPoint: Address;
  chainId: number;
};

export function getPaymasterStubQueryKey(params: GetQueryKeyParams) {
  return [
    'paymasterStubData',
    stringify({ userOp: params.userOp, entryPoint: params.entryPoint, chainId: params.chainId }),
  ];
}

export async function queryPaymasterStubData(params: GetPaymasterStubDataParams) {
  return queryClient.fetchQuery(
    getPaymasterStubQueryKey(params),
    async () => {
      try {
        return await getPaymasterStubData(params);
      } catch (e: ErrorOrAny) {
        if (params.paymasterService?.paymasterURL) {
          throw new PaymasterStubError(e.message);
        }
        return null;
      }
    },
    { staleTime: USER_OP_STALE_TIME_FOR_USER_OP_GENERATION },
  );
}

export async function getPaymasterStubData({
  userOp,
  entryPoint,
  paymasterService,
  chainId,
  dappOrigin,
}: GetPaymasterStubDataParams): Promise<{
  paymasterAndData: string;
  sponsor: { name: string | undefined } | undefined;
}> {
  logPaymasterStubDataStart({ paymasterURL: paymasterService?.paymasterURL, chainId });

  const preVerificationGas = toHex(userOp.preVerificationGas);
  const verificationGasLimit = toHex(userOp.verificationGasLimit);

  const _userOperation = {
    sender: userOp.sender,
    nonce: toHex(userOp.nonce),
    initCode: userOp.initCode,
    callData: userOp.callData,
    callGasLimit: toHex(userOp.callGasLimit),
    verificationGasLimit,
    preVerificationGas,
    maxFeePerGas: toHex(userOp.maxFeePerGas),
    maxPriorityFeePerGas: toHex(userOp.maxPriorityFeePerGas),
    signature: userOp.signature,
  };

  // undefined paymasterUrl allows the backend to substitute in a paymaster when applicable.
  // we should probably move the base sepolia paymaster to the backend and send undefined if not dapp provided in the future
  const paymasterUrl =
    chainId === baseSepolia.id
      ? paymasterService?.paymasterURL ?? BASE_SEPOLIA_PAYMASTER
      : paymasterService?.paymasterURL;
  const params = [
    _userOperation,
    entryPoint,
    numberToHex(chainId),
    {
      dappOrigin,
      paymasterUrl,
      appContext: paymasterService?.dappContext,
    },
  ];

  const result = await callEthereumJSONRPC(
    'pm_getPaymasterStubData',
    params,
    getEthereumRPCURL(chainId),
  );
  if (result.error) {
    logPaymasterStubDataFailure({
      paymasterURL: paymasterUrl,
      chainId,
      errorMessage: result.error.message,
    });
    throw new PaymasterStubError(result.error.message);
  }

  logPaymasterStubDataSuccess({ paymasterURL: paymasterUrl, chainId });
  return result.result;
}
