import {
  txSubmittedFail,
  txSubmittedStart,
  txSubmittedSuccess,
} from 'cb-wallet-analytics/data/Transactions';
import { TxType } from 'cb-wallet-analytics/utils/types/metricTags';
import { TxOrUserOpMetadataKey_txSource } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { ETHEREUM_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { signAndSubmit as signAndSubmitEthereum } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/signAndSubmit';
import { TxOrUserOpMetadataKey_solanaSwapStep } from 'cb-wallet-data/chains/AccountBased/Solana/config';
import { SOLANA_SYMBOL } from 'cb-wallet-data/chains/AccountBased/Solana/constants';
import { signAndSubmitTransaction as signAndSubmitSolana } from 'cb-wallet-data/chains/AccountBased/Solana/Transactions/signAndSubmitTransaction';
import { AllPossibleBlockchainSymbol } from 'cb-wallet-data/chains/blockchains';
import { BITCOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Bitcoin/config';
import { signAndSubmitTransaction as utxoBasedSignAndSubmitTransaction } from 'cb-wallet-data/chains/UTXO/common/signAndSubmitTransaction';
import { DOGECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Dogecoin/config';
import { LITECOIN_SYMBOL } from 'cb-wallet-data/chains/UTXO/Litecoin/config';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { UnsignedTxOrUserOp } from 'cb-wallet-data/stores/Transactions/interfaces/UnsignedTxOrUserOp';
import { TxSubmissionType } from 'cb-wallet-data/stores/Transactions/models/TxSubmissionType';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { LoggedArgument, withEventLogging } from 'cb-wallet-data/utils/withEventLogging';
import { v4 } from 'uuid';
import { ActionType, ComponentType } from '@cbhq/client-analytics';

export type SignAndSubmitArguments = {
  unsignedTxOrUserOp: UnsignedTxOrUserOp;
  wallet: Wallet;
  txSubmissionType: TxSubmissionType;
  onSubmit: (transaction: SignedTx) => void;
  skipSubmit?: boolean;
  // Skip re-calculating gas price, gas limit, and nonce just before sending off the call
  skipPrepare?: boolean;
  source?: string;
  isMEVProtectionEnabled?: boolean;
  txType?: TxType;
  walletProviderName?: string;
};

export type SignAndSubmitReturn = Promise<SignedTx>;

type SignAndSubmit = (args: SignAndSubmitArguments) => SignAndSubmitReturn;

export const SignAndSubmitByChain: Record<AllPossibleBlockchainSymbol, SignAndSubmit> = {
  [ETHEREUM_SYMBOL]: signAndSubmitEthereum,
  [SOLANA_SYMBOL]: signAndSubmitSolana,
  [BITCOIN_SYMBOL]: utxoBasedSignAndSubmitTransaction,
  [DOGECOIN_SYMBOL]: utxoBasedSignAndSubmitTransaction,
  [LITECOIN_SYMBOL]: utxoBasedSignAndSubmitTransaction,
};

/**
 * Sign and submit an unsigned transaction
 *
 * @param unsignedTx The unsigned transaction to sign and submit
 * @param mnemonic The mnemonic with which to sign the transaction
 * @param wallet the signing wallet
 * @param txSubmissionType the type of transaction being submitted (i.e. speed-up, cancel, or original)
 * @param onSubmit
 * @param skipSubmit optional skipping of submission of the transaction (used by some dapps)
 *
 * @param source : where is the transaction submission source:  dapps, extension
 * @return A Promise wrapping a SubmittedTransaction
 */
export async function signAndSubmitTransaction({
  unsignedTxOrUserOp,
  wallet,
  txSubmissionType,
  onSubmit,
  skipSubmit = false,
  skipPrepare = false,
  source = 'extension',
  txType = 'extension',
  isMEVProtectionEnabled = false,
  walletProviderName,
}: SignAndSubmitArguments): SignAndSubmitReturn {
  const blockchainSymbol = wallet.blockchain.rawValue as AllPossibleBlockchainSymbol;
  const chain = unsignedTxOrUserOp.network.asChain();
  const { displayName: chainName, chainId, isCustomNetwork, isTestnet } = chain ?? {};
  const txSource = (
    unsignedTxOrUserOp.metadata.get(TxOrUserOpMetadataKey_txSource) ?? ''
  ).toLocaleString();

  const eventConfig: LoggedArgument<SignedTx> = {
    start: txSubmittedStart,
    success: txSubmittedSuccess,
    fail: txSubmittedFail,
    data: {
      action: ActionType.measurement,
      componentType: ComponentType.unknown,
      currencyCode: unsignedTxOrUserOp.currencyCode.rawValue,
      chainName,
      chainId,
      isCustomNetwork,
      isTestnet,
      isContractDeploy: !unsignedTxOrUserOp.recipientAddress,
      blockchain: unsignedTxOrUserOp.blockchain.rawValue,
      uuid: v4(),
      type: txType,
      source,
      txSource,
      amount: unsignedTxOrUserOp.transferValue.toString(),
      feeCurrencyCode: unsignedTxOrUserOp.feeCurrencyCode.rawValue,
      feeCurrencyDecimal: unsignedTxOrUserOp.feeCurrencyDecimal.toString(),
      fromAddress: unsignedTxOrUserOp.fromAddress,
      fromDomain: unsignedTxOrUserOp.fromDomain,
      toAddress: unsignedTxOrUserOp.recipientAddress,
      toDomain: unsignedTxOrUserOp.recipientDomain,
      network: unsignedTxOrUserOp.network.rawValue,
      ...(unsignedTxOrUserOp.metadata.get(TxOrUserOpMetadataKey_solanaSwapStep) && {
        solanaSwapStep: (
          unsignedTxOrUserOp.metadata.get(TxOrUserOpMetadataKey_solanaSwapStep) ?? ''
        )?.toLocaleString(),
      }),
      walletProviderName,
    },
    metricTags: {
      /* eslint-disable camelcase */
      blockchain: blockchainSymbol,

      // we do not want to log chainId for custom networks
      chain_id: !isCustomNetwork ? chainId?.toString() : undefined,
      tx_source: txSource,
      tx_type: txType,
      /* eslint-enable camelcase */
    },
    addSuccessDataToLog: ({ txHash }) => ({ txHash }),
  };

  const signAndSubmitByChain = SignAndSubmitByChain[blockchainSymbol];

  return withEventLogging(
    async () =>
      signAndSubmitByChain({
        unsignedTxOrUserOp,
        wallet,
        onSubmit,
        txSubmissionType,
        skipSubmit,
        skipPrepare,
        isMEVProtectionEnabled,
      }),
    eventConfig,
  );
}
