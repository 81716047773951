import { Address, Hex } from 'viem';
import {
  CommonPasskeyCredentialBackupEligibility,
  ScwManagercommonSignerType,
} from '@cbhq/instant-api-hooks-wallet-scw/types';

export type PasskeySigner = {
  type: ScwManagercommonSignerType.SIGNER_TYPE_PASSKEY;
  eoaAddress?: never;
  passkeyCredentialId: string;
  passkeyPublicKey: Hex;
  index: string;
  passkeyAaguid?: string;
  passkeyIsBackupEligible: CommonPasskeyCredentialBackupEligibility;
};

export type RecoveryKeySigner = {
  type:
    | ScwManagercommonSignerType.SIGNER_TYPE_RECOVERY_KEY
    | ScwManagercommonSignerType.SIGNER_TYPE_EOA;
  passkeyCredentialId?: never;
  passkeyPublicKey?: never;
  passkeyAaguid?: never;
  passkeyIsBackupEligible?: never;
  index: string;
  eoaAddress: string;
};

export type Signer = PasskeySigner | RecoveryKeySigner;

export type SignerWithRecoveryKey = Omit<Signer, 'index'> & {
  recoveryKey: string;
};

export type MagicSpendSignature = {
  signature: Hex;
  nonce: string;
  targetAssetContractAddress: Address;
  amount: string;
  coinbaseFee: string;
  piggyBankAddress: Address;
  expiresAt: string;
  ownerAddress: Address;
};

export type MagicSpendQuoteSignature = MagicSpendSignature & {
  commitId: string;
  mfaRequired: boolean;
};

export type MagicSpendQuoteSignatureResponse = Omit<MagicSpendQuoteSignature, 'expiresAt'> & {
  commitId: string;
  quoteExpiryAt: string;
  mfaRequired: boolean;
};

export type MagicSpendSignatureCommitResponse = Omit<MagicSpendSignature, 'expiresAt'> & {
  signatureExpiryAt: string;
};

export enum MagicSpendFlow {
  GAS_ONLY = 'gasOnly', // Use magic spend to pay gas only, the WithdrawRequest is attached to paymasterAndData and no callData is attached
  VALUE_ONLY = 'valueOnly', // Use magic spend to transfer value only, the WithdrawRequest is attached to `withdraw` in execution callData and paymasterAndData is empty
  GAS_AND_VALUE = 'gasAndValue', // Use magic spend to pay gas and transfer fund, the WithdrawRequest is attached to paymasterAndData and call `withdrawGasExcess` in execution callData
}
