import { UnsignedUserOp, UserOp } from '../types';

export type GasCostForUserOpParams =
  | Pick<UserOp, 'callGasLimit' | 'verificationGasLimit' | 'preVerificationGas' | 'maxFeePerGas'>
  | Pick<
      UnsignedUserOp,
      'callGasLimit' | 'verificationGasLimit' | 'preVerificationGas' | 'maxFeePerGas'
    >;
export function getGasCostForUserOp(userOp?: GasCostForUserOpParams) {
  const gasUsed =
    BigInt(userOp?.callGasLimit ?? 0n) +
    BigInt(userOp?.verificationGasLimit ?? 0n) +
    BigInt(userOp?.preVerificationGas ?? 0n);
  const gasCost = gasUsed * BigInt(userOp?.maxFeePerGas ?? 0n);

  return gasCost;
}
