import { MAGIC_SPEND_WEI_GAS_PADDING } from 'cb-wallet-data/MagicSpend/constants';
import { MagicSpendFlow } from 'cb-wallet-data/scw/api/types';
import { EstimateUserOperationGasReturnType } from 'permissionless';

import { Call } from '../types';

type GetMagicSpendRequestAmountParams = {
  calls: Call[];
  maxFeePerGas: bigint;
  magicSpendFlow: MagicSpendFlow;
  gasEstimatesWithoutPaymaster: EstimateUserOperationGasReturnType;
};

export function getMagicSpendRequestAmount({
  calls,
  maxFeePerGas,
  magicSpendFlow,
  gasEstimatesWithoutPaymaster,
}: GetMagicSpendRequestAmountParams) {
  const value = calls.reduce((acc, call) => acc + call.value, 0n);

  if (magicSpendFlow === MagicSpendFlow.VALUE_ONLY) {
    return value;
  }

  // gas is calculated as https://github.com/eth-infinitism/account-abstraction/blob/abff2aca61a8f0934e533d0d352978055fddbd96/contracts/core/EntryPoint.sol#L330
  const requiredGas =
    gasEstimatesWithoutPaymaster.callGasLimit +
    gasEstimatesWithoutPaymaster.verificationGasLimit * 3n +
    gasEstimatesWithoutPaymaster.preVerificationGas +
    (magicSpendFlow === MagicSpendFlow.GAS_AND_VALUE ? MAGIC_SPEND_WEI_GAS_PADDING : 0n);

  const requiredPrefund = maxFeePerGas * requiredGas;

  return value + requiredPrefund;
}
