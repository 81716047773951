import { baseSepolia } from '@wagmi/core/chains';
import {
  logPaymasterSignatureFailure,
  logPaymasterSignatureStart,
  logPaymasterSignatureSuccess,
} from 'cb-wallet-analytics/scw/Paymaster';
import { getEthereumRPCURL } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { queryClient } from 'cb-wallet-data/QueryProvider';
import { DUMMY_SIGNATURE } from 'cb-wallet-data/scw/features/sign/constants';
import { PaymasterSignatureError } from 'cb-wallet-data/scw/features/sign/transaction/hooks/UseUnsignedUserOpErrors';
import {
  PaymasterService,
  UnsignedUserOp,
} from 'cb-wallet-data/scw/features/sign/transaction/types';
import { stringify } from 'cb-wallet-store/utils/serialization';
import { Address, numberToHex, toHex } from 'viem';
import { callEthereumJSONRPC } from 'wallet-engine-signing/blockchains/Ethereum/RPC';

export const BASE_SEPOLIA_PAYMASTER = 'https://paymaster.base.org';

type GetPaymasterSignatureParams = {
  userOp: UnsignedUserOp;
  entryPoint: Address;
  paymasterService?: PaymasterService;
  chainId: number;
  dappOrigin: string;
};
export function getPaymasterSignatureQueryKey({
  userOp,
  entryPoint,
  chainId,
}: {
  userOp?: UnsignedUserOp;
  entryPoint: Address;
  chainId: number;
}) {
  return ['paymasterSignature', stringify({ userOp, entryPoint, chainId })];
}
export async function queryPaymasterSignature({
  userOp,
  entryPoint,
  paymasterService,
  chainId,
  dappOrigin,
}: GetPaymasterSignatureParams) {
  return queryClient.fetchQuery({
    queryKey: getPaymasterSignatureQueryKey({ userOp, entryPoint, chainId }),
    queryFn: async () =>
      getPaymasterSignatureAndHandleError({
        userOp,
        entryPoint,
        chainId,
        paymasterService,
        dappOrigin,
      }),
  });
}

export async function getPaymasterSignatureAndHandleError({
  userOp,
  entryPoint,
  chainId,
  paymasterService,
  dappOrigin,
}: GetPaymasterSignatureParams) {
  try {
    const promise = getPaymasterSignature({
      userOp,
      entryPoint,
      paymasterService,
      chainId,
      dappOrigin,
    });

    const data = await promise;

    return data;
  } catch (e: ErrorOrAny) {
    if (paymasterService?.paymasterURL) {
      throw new PaymasterSignatureError(e.message);
    }
    return undefined;
  }
}

export async function getPaymasterSignature({
  userOp,
  entryPoint,
  paymasterService,
  chainId,
  dappOrigin,
}: GetPaymasterSignatureParams): Promise<{ paymasterAndData: `0x${string}` }> {
  logPaymasterSignatureStart({ paymasterURL: paymasterService?.paymasterURL, chainId });
  const preVerificationGas = toHex(userOp.preVerificationGas);
  const verificationGasLimit = toHex(userOp.verificationGasLimit);

  const _userOperation = {
    sender: userOp.sender,
    nonce: toHex(userOp.nonce),
    initCode: userOp.initCode,
    callData: userOp.callData,
    callGasLimit: toHex(userOp.callGasLimit),
    verificationGasLimit,
    preVerificationGas,
    maxFeePerGas: toHex(userOp.maxFeePerGas),
    maxPriorityFeePerGas: toHex(userOp.maxPriorityFeePerGas),
    signature: DUMMY_SIGNATURE,
    paymasterAndData: '0x',
  };

  // undefined paymasterUrl allows the backend to substitute in a paymaster when applicable.
  // we should probably move the base sepolia paymaster to the backend and send undefined if not dapp provided in the future
  const paymasterUrl =
    chainId === baseSepolia.id
      ? paymasterService?.paymasterURL ?? BASE_SEPOLIA_PAYMASTER
      : paymasterService?.paymasterURL;
  const params = [
    _userOperation,
    entryPoint,
    numberToHex(chainId),
    {
      dappOrigin,
      paymasterUrl,
      appContext: paymasterService?.dappContext,
    },
  ];

  const result = await callEthereumJSONRPC(
    'pm_getPaymasterData',
    params,
    getEthereumRPCURL(chainId),
  );
  if (result.error) {
    logPaymasterSignatureFailure({
      paymasterURL: paymasterUrl,
      chainId,
      errorMessage: result.error.message,
    });
    throw new PaymasterSignatureError(result.error.message);
  }

  logPaymasterSignatureSuccess({ paymasterURL: paymasterUrl, chainId });
  return {
    paymasterAndData: result.result.paymasterAndData,
  };
}
