import { getConfirmedEthereumTransactionCount } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { getUnminedSignedTxsAfterNonce } from 'cb-wallet-data/chains/AccountBased/Ethereum/database';
import {
  EthereumChain,
  EthereumNetworkMap,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { EthereumSignedTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import { EthereumUnsignedLegacyTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumUnsignedLegacyTx';
import { SolanaChain } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { v4 as uuidv4 } from 'uuid';
import { bnFromBigInt, prepend0x } from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';
import { SignedEthereumTransaction } from 'wallet-engine-signing/signing/generated/EthereumService.generated';

import { ethereumAddressDerivationPath } from '../config';

import { calculateNextNonce } from './utils/nonce';

/**
 * Run preparatory calculations on the given transaction and pass to the sign function
 *
 * @param tx The transaction to sign
 * @param accountId The accountId to sign the transaction with
 * @param skipNonceCheck `true` when we want to set nonce manually without any validations, `false` otherwise.
 *                        This is used when generating multiple signed tx hashes to get gas estimates in a local
 *                        fork of mainnet.
 * @param skipSubmit `true` when the caller wants us to sign the transaction without submitting. `false` otherwise.
 * @param skipPrepare `true` when the caller wants us to skip just-in-time re-calculation of nonce, gas price, and gas
 * limit. non-wallet applications usually have no need for these calculations.
 * @param isMEVProtectionEnabled `true` when the transaction should be submitted via a private mining pool. `false` otherwise
 *
 * @return The signed transaction
 */
export async function signAndSubmitTransaction({
  tx,
  accountId,
  skipNonceCheck = false,
  skipSubmit = false,
  skipPrepare = false,
  isMEVProtectionEnabled,
}: {
  tx: EthereumUnsignedLegacyTx;
  accountId: string;
  skipSubmit?: boolean;
  isMEVProtectionEnabled: boolean;
  skipPrepare?: boolean;
  skipNonceCheck?: boolean;
}): Promise<EthereumSignedTx> {
  const ethereumChain = tx.network.asChain();
  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(tx.network);
  }

  const derivationPath = ethereumAddressDerivationPath(tx.walletIndex);

  // nonce is irrelevant for `dapp` since the wallet will ultimately derive the nonce
  let nonce = 0n;
  if (!skipPrepare) {
    nonce = await deriveNonce({
      tx,
      skipNonceCheck,
      ethereumChain,
    });
  }

  return _signAndSubmitTransaction(
    tx,
    derivationPath,
    accountId,
    nonce,
    skipSubmit,
    skipPrepare,
    isMEVProtectionEnabled,
  );
}

async function deriveNonce({
  tx,
  skipNonceCheck = false,
  ethereumChain,
}: {
  tx: EthereumUnsignedLegacyTx;
  skipNonceCheck?: boolean;
  ethereumChain: EthereumChain | SolanaChain;
}) {
  // get most recent nonce using CipherCore
  const confirmedTxCount = await getConfirmedEthereumTransactionCount(tx.fromAddress, tx.network);

  // Get list of signed transactions from db with nonce greater than most recent confirmed
  // transaction count
  const pendingSignedTxs = await getUnminedSignedTxsAfterNonce(
    confirmedTxCount,
    BigInt(ethereumChain.chainId),
    tx.fromAddress,
  );

  let nonce;

  const ethChain = tx.network.asChain() as EthereumChain;
  if (ethChain?.chainId === EthereumNetworkMap.whitelisted.LOCALHOST_8545.chainId) {
    nonce = confirmedTxCount;
  } else {
    nonce = await calculateNextNonce(tx, confirmedTxCount, pendingSignedTxs, skipNonceCheck);
  }

  return nonce;
}

/**
 * Sign ethereum transaction
 */
async function _signAndSubmitTransaction(
  tx: EthereumUnsignedLegacyTx,
  derivationPath: string,
  accountId: string,
  nonce: bigint,
  skipSubmit: boolean,
  skipPrepare: boolean,
  isMEVProtectionEnabled: boolean,
): Promise<EthereumSignedTx> {
  const ethereumChain = tx.network.asChain();
  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(tx.network);
  }
  let signedTransaction: SignedEthereumTransaction;
  // Can remove this check once L2 networks start supporting Berlin and London transaction payloads

  // chainID 1337 and 31337 are hardhat custom network, which is used on dev mode
  const isEthMainnetOrTestnet = [1, 3, 4, 5, 42, 1337, 31337].includes(ethereumChain.chainId);

  if (isEthMainnetOrTestnet) {
    const signAndSubmitETH2930Transaction = getSigningOrDerivationMethodForAccount(
      accountId,
      'signAndSubmitETH2930Transaction',
    );

    signedTransaction = await signAndSubmitETH2930Transaction({
      accountId,
      derivationPath,
      skipSubmit,
      skipPrepare,
      isMEVProtectionEnabled,
      unsignedTx: {
        toAddress: tx.toAddress ?? null,
        weiValue: bnFromBigInt(tx.weiValue),
        data: tx.data,
        nonce: Number(nonce),
        gasPriceInWei: bnFromBigInt(tx.gasPrice),
        gasLimit: bnFromBigInt(tx.gasLimit),
        chainId: ethereumChain.chainId,
      },
    });
  } else {
    const signAndSubmitETHLegacyTransaction = getSigningOrDerivationMethodForAccount(
      accountId,
      'signAndSubmitETHLegacyTransaction',
    );

    signedTransaction = await signAndSubmitETHLegacyTransaction({
      accountId,
      derivationPath,
      skipSubmit,
      skipPrepare,
      isMEVProtectionEnabled,
      unsignedTx: {
        toAddress: tx.toAddress ?? null,
        weiValue: bnFromBigInt(tx.weiValue),
        data: tx.data,
        nonce: Number(nonce),
        gasPriceInWei: bnFromBigInt(tx.gasPrice),
        gasLimit: bnFromBigInt(tx.gasLimit),
        chainId: ethereumChain.chainId,
      },
    });
  }

  // Create new `SignedTransaction` instance using the signed data
  return new EthereumSignedTx(
    uuidv4(),
    tx.fromAddress,
    tx.toAddress,
    nonce,
    BigInt(ethereumChain.chainId),
    signedTransaction.data,
    prepend0x(signedTransaction.hash.toString('hex')),
    tx.weiValue,
    tx.erc20Value,
    tx.blockchain,
    tx.currencyCode,
    TxState.PENDING,
    0n,
  );
}
