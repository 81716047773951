import { ActionType, ComponentType } from '@cbhq/client-analytics';

import { logEvent } from '../utils/log';

export type LogPaymasterReceivedParams = {
  paymasterURL?: string;
  chainId: number;
  isPaymasterEnabled: boolean;
};

export function logPaymasterReceived({
  paymasterURL,
  chainId,
  isPaymasterEnabled,
}: LogPaymasterReceivedParams) {
  return logEvent('paymaster_received', {
    action: ActionType.click,
    componentType: ComponentType.button,
    paymasterURL,
    chainId,
    isPaymasterEnabled,
  });
}
export function logPaymasterStubDataStart({
  paymasterURL,
  chainId,
}: {
  paymasterURL: string | undefined;
  chainId: number;
}) {
  return logEvent('paymaster_stub_data_start', {
    componentType: ComponentType.button,
    action: ActionType.click,
    data: { paymasterURL, chainId },
  });
}

export function logPaymasterStubDataSuccess({
  paymasterURL,
  chainId,
}: {
  paymasterURL: string | undefined;
  chainId: number;
}) {
  return logEvent('paymaster_stub_data_success', {
    componentType: ComponentType.button,
    action: ActionType.click,
    data: { paymasterURL, chainId },
  });
}

export function logPaymasterSignatureSuccess({
  paymasterURL,
  chainId,
}: {
  paymasterURL: string | undefined;
  chainId: number;
}) {
  return logEvent('paymaster_signature_success', {
    componentType: ComponentType.button,
    action: ActionType.click,
    data: { paymasterURL, chainId },
  });
}

export function logPaymasterStubDataFailure({
  paymasterURL,
  chainId,
  errorMessage,
}: {
  paymasterURL: string | undefined;
  chainId: number;
  errorMessage: string | undefined;
}) {
  return logEvent('paymaster_stub_data_failure', {
    componentType: ComponentType.button,
    action: ActionType.click,
    data: { paymasterURL, chainId, errorMessage },
  });
}

export function logPaymasterSignatureStart({
  paymasterURL,
  chainId,
}: {
  paymasterURL: string | undefined;
  chainId: number;
}) {
  return logEvent('paymaster_signature_start', {
    componentType: ComponentType.button,
    action: ActionType.click,
    data: { paymasterURL, chainId },
  });
}

export function logPaymasterSignatureFailure({
  paymasterURL,
  chainId,
  errorMessage,
}: {
  paymasterURL: string | undefined;
  chainId: number;
  errorMessage: string | undefined;
}) {
  return logEvent('paymaster_signature_failure', {
    componentType: ComponentType.button,
    action: ActionType.click,
    data: { paymasterURL, chainId, errorMessage },
  });
}
