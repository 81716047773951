import { getBytecode } from '@wagmi/core';
import { queryClient } from 'cb-wallet-data/QueryProvider';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { stringify } from 'cb-wallet-store/utils/serialization';

export type QueryBytecodeParms = {
  chainId: number;
  address: `0x${string}`;
};
export async function queryBytecode({
  chainId,
  address,
}: QueryBytecodeParms): Promise<`0x${string}` | null> {
  const config = getConfig();
  return queryClient.fetchQuery(
    ['queryBytecode', stringify({ chainId, address })],
    async () =>
      // Copied from wagmi's getBytecodeQueryOptions function which coalesces null here
      (await getBytecode(config, { chainId, address })) ?? null,
  );
}
