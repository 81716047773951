// https://github.com/Vectorized/solady/blob/1f43cc8005cc3b3c8361dd7dbdd2cdeaf0f99e66/src/auth/Ownable.sol#L69
// It is intentionally chosen to be a high value
// to avoid collision with lower slots.
// The choice of manual storage layout is to enable compatibility
// with both regular and upgradeable contracts.
export const MAGIC_SPEND_OWNER_SLOT =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffff74873927';

// If we are using magic spend to withdraw funds for a transaction that requires ETH, we need to
// pad the call gas limit by 8000 to account for the withdrawGasExcess call.
// https://docs.google.com/document/d/1AjYFlILePpO8sB8tyz2SGia0h132IqhzZhVfFz3AnUU/edit#bookmark=id.8ncgidcbeqf3
export const MAGIC_SPEND_WEI_GAS_PADDING = 8000n;
