import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { tryGetRepository } from 'cb-wallet-data/persistence/Database';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';

import { UTXOSignedTx, UTXOSignedTxDMO } from './models/UTXOSignedTx';

function repository() {
  return tryGetRepository<UTXOSignedTxDMO>('utxo_signed_tx');
}

/**
 * Save or update given UTXO signed transaction
 *
 * @param tx the UTXOSignedTx to save
 */
export async function save(tx: UTXOSignedTx): Promise<UTXOSignedTx> {
  await repository().upsert(tx.asDMO, ['id']);
  return tx;
}

/**
 * Get list of unconfirmed signed transaction
 *
 * @param blockchain Blockchain to filter by
 * @param currencyCode Currency code to match against
 * @param network network state to match against
 */
export async function getPendingTxs(
  blockchain: Blockchain,
  currencyCode: CurrencyCode,
  network: Network,
): Promise<UTXOSignedTx[]> {
  const dmoArray = await repository().find({
    where: {
      state: TxState.PENDING.valueOf(),
      currencyCodeStr: currencyCode.rawValue,
      blockchainStr: blockchain.rawValue,
      networkStr: network.rawValue,
    },
  });

  return dmoArray.map(UTXOSignedTx.fromDMO);
}
