import { accountFactoryAbi, accountFactoryAddress } from 'cb-wallet-data/scw/libs/wagmi/generated';
import { Address, Chain, Client, encodeFunctionData, Hex, Transport } from 'viem';
import { readContract } from 'viem/actions';

type GetAccountAddressParams = {
  owners: Hex[];
  nonce: bigint;
};

/**
 * Gets the counterfactual address of an account given the owners and nonce
 * @param client Public viem cliet to make an onchain call
 * @param owners Array of owners' public keys
 * @param nonce Nonce used as salt for account creation
 *
 * @returns The counterfactual address of the account
 */
export async function getAccountAddress<TChain extends Chain | undefined>(
  client: Client<Transport, TChain>,
  { owners, nonce }: GetAccountAddressParams,
): Promise<Address> {
  const counterfactualAddress = await readContract(client, {
    abi: accountFactoryAbi,
    address: accountFactoryAddress,
    functionName: 'getAddress',
    args: [owners, nonce],
  });
  return counterfactualAddress;
}

type BuildCreateAccountCalldataParams = {
  owners: Hex[];
  nonce: bigint;
};

/**
 * Builds factory calldata given the owners and nonce
 * @param owners Array of owners' public keys
 * @param nonce Nonce used as salt for account creation
 *
 * @returns The factory calldata to create an account
 */
export function buildCreateAccountCalldata({
  owners,
  nonce,
}: BuildCreateAccountCalldataParams): Hex {
  return encodeFunctionData({
    abi: accountFactoryAbi,
    functionName: 'createAccount',
    args: [owners, nonce],
  });
}
