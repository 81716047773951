import { get1559GasPrice } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/utils/gas';

import { Get1559GasPricesError } from '../hooks/UseUnsignedUserOpErrors';

type GetQueryParams = {
  chainId: number;
};

export function getQuerykey(params: GetQueryParams) {
  return ['get1559GasPrices', params.chainId];
}

export async function get1559GasPricesAndHandleError({ chainId }: GetQueryParams) {
  const gasPrices = await get1559GasPrice(chainId);

  if (gasPrices.error) {
    throw new Get1559GasPricesError(gasPrices.error.message);
  }

  return gasPrices;
}
