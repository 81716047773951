import { getERC20Balance } from 'cb-wallet-data/chains/AccountBased/Ethereum/Balance/getERC20Balance';
import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { EthereumUnsignedLegacyTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumUnsignedLegacyTx';
import { EthTxConfigLegacy } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/chainConfigs';
import { encodeERC20Transfer } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/encodeERC20Transfer';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import {
  GenerateUnsignedTransactionArgs,
  GenerateUnsignedTransactionResult,
} from 'cb-wallet-data/stores/Transactions/methods/generateUnsignedTransaction';

import { generateUnsignedLegacyTransaction } from './generateUnsignedLegacyTransaction';

/**
 * Generate ERC20 unsigned transaction
 */
export async function generateUnsignedLegacyERC20Transaction({
  wallet,
  recipientAddress,
  transactionAmount,
  metadata,
  txOrUserOpConfig,
}: GenerateUnsignedTransactionArgs): GenerateUnsignedTransactionResult {
  const { nonce } = txOrUserOpConfig as EthTxConfigLegacy;

  if (!wallet.contractAddress) throw EthereumError.invalidERC20;

  const value =
    transactionAmount.kind === 'Amount'
      ? transactionAmount.value
      : await getERC20Balance(wallet.primaryAddress, wallet.contractAddress, wallet.network);

  const encodeResult = encodeERC20Transfer(recipientAddress, wallet.contractAddress, value);

  const config = {
    ...txOrUserOpConfig,
    dataBuffer: encodeResult.data,
  };

  const unsignedTxResult = await generateUnsignedLegacyTransaction({
    wallet,
    recipientAddress: encodeResult.toAddress,
    transactionAmount: { kind: 'Amount', value: 0n },
    metadata,
    txOrUserOpConfig: config,
  });

  if (!(unsignedTxResult.transaction instanceof EthereumUnsignedLegacyTx)) {
    throw EthereumError.unableToFindSignedTx;
  }

  const ethereumUnsignedTx: EthereumUnsignedLegacyTx = unsignedTxResult.transaction;

  const [fromDomain, contractDomain, recipientDomain] = await fetchDomains([
    ethereumUnsignedTx.fromAddress,
    wallet.contractAddress,
    recipientAddress,
  ]);

  const transaction = EthereumUnsignedLegacyTx.createERC20Tx({
    fromAddress: ethereumUnsignedTx.fromAddress,
    fromDomain,
    walletIndex: ethereumUnsignedTx.walletIndex,
    contractAddress: wallet.contractAddress,
    contractDomain,
    recipientAddress,
    recipientDomain,
    nonce,
    erc20Value: value,
    data: ethereumUnsignedTx.data,
    l1GasFee: ethereumUnsignedTx.l1GasFee,
    gasPrice: ethereumUnsignedTx.gasPrice,
    gasLimit: ethereumUnsignedTx.gasLimit,
    network: ethereumUnsignedTx.network,
    currencyCode: ethereumUnsignedTx.currencyCode,
    feeCurrencyCode: ethereumUnsignedTx.feeCurrencyCode,
    feeCurrencyDecimal: ethereumUnsignedTx.feeCurrencyDecimal,
    blockchain: ethereumUnsignedTx.blockchain,
    metadata,
  });

  if (unsignedTxResult.kind === 'Success') {
    return {
      kind: 'Success',
      transaction,
      wallet,
      err: undefined,
    };
  }

  return {
    kind: 'Failure',
    wallet,
    err: EthereumError.unableToEstimateGas,
  };
}
