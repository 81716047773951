export class SolanaError extends Error {
  constructor(readonly name: string, readonly message: string, readonly stack?: string) {
    super(message);
  }

  /**
   * Thrown when unable to find wallet used for signature
   */
  static unableToFindWallet = new SolanaError('UnableToFindWallet', '');

  /**
   * Thrown when unable to cast [Configuration] to an [SolanaBasedConfiguration]
   */
  static invalidConfiguration = new SolanaError('InvalidConfiguration', '');

  /**
   * throw if rpc to get recent block failed
   */
  static unableToGenerateUnsignedTransaction = new SolanaError(
    'UnableToGenerateUnsignedTransaction',
    'Unable to generate unsigned transaction',
  );

  /**
   * throw if rpc to get recent block failed
   */
  static unableToGetRecentBlockHash = new SolanaError(
    'UnableToGetRecentBlockHash',
    'Unable to get block hash',
  );

  /**
   * This means transaction failed the simulation and can't be submitted
   */
  static transactionSimulationFailed = new SolanaError(
    'TransactionSimulationFailed',
    'Transaction simulation failed',
  );

  /**
   * This means the RPC call to sendTransaction did not return a txHash
   */
  static unableToSubmitTransaction = new SolanaError(
    'TransactionSubmissionFailed',
    'Unable to submit transaction',
  );

  /**
   * When dapps send a transaction with wrong signer to extension to sign
   */
  static wrongSignerFromDapp = new SolanaError(
    'DappTransactionWrongSigner',
    'Wrong signer address from request',
  );

  /**
   * When dapps send a transaction without a feePayer
   */
  static noFeePayerFound = new SolanaError(
    'NoFeePayerFound',
    'Transaction does not have a fee payer',
  );

  /**
   * When we are unable to get the network fee
   */
  static unableToGetNetworkFee = new SolanaError(
    'UnableToGetNetworkFee',
    'Unable to get network fee',
  );
}
