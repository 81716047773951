import { UnsignedUserOp } from '../types';

export function getGasCostLowerBoundForUserOp(
  userOp?: Pick<
    UnsignedUserOp,
    | 'callGasLimit'
    | 'verificationGasLimit'
    | 'preVerificationGas'
    | 'maxFeePerGas'
    | 'additionalParameters'
    | 'maxPriorityFeePerGas'
  >,
) {
  const gasUsed =
    BigInt(userOp?.callGasLimit ?? 0n) +
    BigInt(userOp?.verificationGasLimit ?? 0n) +
    BigInt(userOp?.preVerificationGas ?? 0n);
  const gasCost =
    gasUsed *
    (BigInt(userOp?.additionalParameters?.baseFeePerGas ?? 0n) +
      BigInt(userOp?.maxPriorityFeePerGas ?? 0n));

  return gasCost;
}
