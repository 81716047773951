import { getConfirmedEthereumTransactionCount } from 'cb-wallet-data/chains/AccountBased/Ethereum/apis/EthereumRPC';
import { getUnminedSignedTxsAfterNonce } from 'cb-wallet-data/chains/AccountBased/Ethereum/database';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { EthereumSignedTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import { EthereumUnsigned1559Tx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumUnsigned1559Tx';
import { SolanaChain } from 'cb-wallet-data/chains/AccountBased/Solana/models/SolanaChain';
import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { v4 as uuidv4 } from 'uuid';
import { bnFromBigInt, prepend0x } from 'wallet-engine-signing/blockchains/Ethereum/formatNumbers';

import { ethereumAddressDerivationPath } from '../config';

import { calculateNextNonce } from './utils/nonce';

// TODO consolidate the two functions in this file

/**
 * Run preparatory calculations on the given transaction and pass to the sign function
 */
export async function signAndSubmit1559Transaction({
  tx,
  accountId,
  skipNonceCheck = false,
  skipSubmit = false,
  skipPrepare = false,
  isMEVProtectionEnabled,
}: {
  tx: EthereumUnsigned1559Tx;
  accountId: string;
  skipNonceCheck?: boolean;
  skipSubmit?: boolean;
  skipPrepare?: boolean;
  isMEVProtectionEnabled: boolean;
}): Promise<EthereumSignedTx> {
  const ethereumChain = tx.network.asChain();
  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(tx.network);
  }

  const chainId = ethereumChain.chainId;

  const signAndSubmitETH1559Transaction = getSigningOrDerivationMethodForAccount(
    accountId,
    'signAndSubmitETH1559Transaction',
  );

  const derivationPath = ethereumAddressDerivationPath(tx.walletIndex);

  // nonce is irrelevant for `dapp` since the wallet will ultimately derive the nonce
  let nonce = 0n;
  if (!skipPrepare) {
    nonce = await deriveNonce({
      tx,
      ethereumChain,
      skipNonceCheck,
    });
  }

  const signedTransaction = await signAndSubmitETH1559Transaction({
    accountId,
    derivationPath,
    skipSubmit,
    skipPrepare,
    isMEVProtectionEnabled,
    unsignedTx: {
      toAddress: tx.toAddress,
      weiValue: bnFromBigInt(tx.weiValue),
      data: tx.data,
      nonce: Number(nonce),
      maxFeePerGas: bnFromBigInt(tx.maxFeePerGas),
      maxPriorityFeePerGas: bnFromBigInt(tx.maxPriorityFeePerGas),
      gasLimit: bnFromBigInt(tx.gasLimit),
      chainId,
    },
  });

  // Create new `SignedTransaction` instance using the signed data
  return new EthereumSignedTx(
    uuidv4(),
    tx.fromAddress,
    tx.toAddress,
    nonce,
    BigInt(chainId),
    signedTransaction.data,
    prepend0x(signedTransaction.hash.toString('hex')),
    tx.weiValue,
    tx.erc20Value,
    tx.blockchain,
    tx.currencyCode,
    TxState.PENDING,
    0n,
  );
}

async function deriveNonce({
  tx,
  skipNonceCheck = false,
  ethereumChain,
}: {
  tx: EthereumUnsigned1559Tx;
  skipNonceCheck?: boolean;
  ethereumChain: EthereumChain | SolanaChain;
}) {
  // get most recent nonce using CipherCore
  const confirmedTxCount = await getConfirmedEthereumTransactionCount(tx.fromAddress, tx.network);

  // Get list of signed transactions from db with nonce greater than most recent confirmed
  // transaction count
  const pendingSignedTxs = await getUnminedSignedTxsAfterNonce(
    BigInt(confirmedTxCount),
    BigInt(ethereumChain.chainId),
    tx.fromAddress,
  );

  // calculate nonce
  const nonce = await calculateNextNonce(tx, confirmedTxCount, pendingSignedTxs, skipNonceCheck);
  return nonce;
}
