import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxFlowOrType, TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataValue,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { TxSubmissionType } from 'cb-wallet-data/stores/Transactions/models/TxSubmissionType';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 } from 'uuid';

import { EthereumUnsignedTx } from './EthereumUnsignedTx';

type TransactionData = {
  fromAddress: string;
  fromDomain: string | undefined;
  walletIndex: bigint;
  toAddress: string | undefined;
  toDomain: string | undefined;
  nonce: bigint | undefined;
  weiValue: bigint;
  data: Buffer;
  l1GasFee: bigint | undefined;
  gasPrice: bigint;
  gasLimit: bigint;
  network: Network;
  currencyCode: CurrencyCode;
  feeCurrencyCode: CurrencyCode;
  feeCurrencyDecimal: bigint;
  blockchain: Blockchain;
  metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>;
};

type ERC20TransactionData = {
  fromAddress: string;
  fromDomain: string | undefined;
  walletIndex: bigint;
  contractAddress: string;
  contractDomain: string | undefined;
  recipientAddress: string;
  recipientDomain: string | undefined;
  nonce: bigint | undefined;
  erc20Value: bigint;
  data: Buffer;
  l1GasFee: bigint | undefined;
  gasPrice: bigint;
  gasLimit: bigint;
  network: Network;
  currencyCode: CurrencyCode;
  feeCurrencyCode: CurrencyCode;
  feeCurrencyDecimal: bigint;
  blockchain: Blockchain;
  metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>;
};

/**
 * Represents an unsigned ethereum transaction ie. erc20, ether transfers, etc
 *
 * @property toAddress The address the transaction is being sent to
 * @property fromAddress The address the transaction is being sent from
 * @property walletIndex from address HD index
 * @property nonce The nonce of the transaction if one was specific
 * @property weiValue The amount of ether (in wei) being sent with the transaction. Corresponds to 'value' in standard
 * eth tx. Only should be non-zero if we are transferring ETH to another account
 * @property erc20Value The ERC20 transfer amount or null if not an ERC20
 * @property data The transaction data
 * @property gasPrice The gas price in wei
 * @property gasLimit The gas limit
 * @property chainId The chain ID of the transaction
 * @property currencyCode The currency code of the transaction
 * @property blockchain Blockchain that for this transaction
 * @property transferValue The transfer value for non-ERC20 txs will always be the wei value, ERC20 txs uses erc20Value
 */
export class EthereumUnsignedLegacyTx implements EthereumUnsignedTx {
  // eslint-disable-next-line max-params
  constructor(
    readonly toAddress: string | undefined,
    readonly toDomain: string | undefined,
    readonly fromAddress: string,
    readonly fromDomain: string | undefined,
    readonly walletIndex: bigint,
    readonly nonce: bigint | undefined,
    readonly weiValue: bigint,
    readonly erc20Value: bigint | undefined,
    readonly data: Buffer,
    readonly l1GasFee: bigint | undefined,
    readonly gasPrice: bigint,
    readonly gasLimit: bigint,
    readonly network: Network,
    readonly currencyCode: CurrencyCode,
    readonly blockchain: Blockchain,
    readonly transferValue: bigint,
    readonly feeCurrencyCode: CurrencyCode,
    readonly feeCurrencyDecimal: bigint,
    readonly recipientAddress: string,
    readonly recipientDomain: string | undefined,
    readonly metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue> = new Map(),
  ) {
    this.estimatedFee = gasPrice * gasLimit + (l1GasFee ?? 0n);
  }

  readonly estimatedFee: bigint;

  get maxFeePerGas(): bigint {
    throw EthereumError.unsupported1559Property;
  }

  get baseFeePerGas(): bigint {
    throw EthereumError.unsupported1559Property;
  }

  get maxPriorityFeePerGas(): bigint {
    throw EthereumError.unsupported1559Property;
  }

  /**
   * Custom constructor that generates an ether transaction
   */
  static createEtherTx({
    fromAddress,
    fromDomain,
    walletIndex,
    toAddress,
    toDomain,
    nonce,
    weiValue,
    data,
    l1GasFee,
    gasPrice,
    gasLimit,
    network,
    currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    blockchain,
    metadata,
  }: TransactionData): EthereumUnsignedLegacyTx {
    return new EthereumUnsignedLegacyTx(
      toAddress,
      toDomain,
      fromAddress,
      fromDomain,
      walletIndex,
      nonce,
      weiValue,
      undefined,
      data,
      l1GasFee,
      gasPrice,
      gasLimit,
      network,
      currencyCode,
      blockchain,
      weiValue,
      feeCurrencyCode,
      feeCurrencyDecimal,
      toAddress ?? '',
      toDomain,
      metadata,
    );
  }

  /**
   * Custom constructor that generates an ERC20 transaction
   */
  static createERC20Tx({
    fromAddress,
    fromDomain,
    walletIndex,
    contractAddress,
    contractDomain,
    recipientAddress,
    recipientDomain,
    nonce,
    erc20Value,
    data,
    l1GasFee,
    gasPrice,
    gasLimit,
    network,
    currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    blockchain,
    metadata,
  }: ERC20TransactionData): EthereumUnsignedLegacyTx {
    return new EthereumUnsignedLegacyTx(
      contractAddress,
      contractDomain,
      fromAddress,
      fromDomain,
      walletIndex,
      nonce,
      0n,
      erc20Value,
      data,
      l1GasFee,
      gasPrice,
      gasLimit,
      network,
      currencyCode,
      blockchain,
      erc20Value,
      feeCurrencyCode,
      feeCurrencyDecimal,
      recipientAddress,
      recipientDomain,
      metadata,
    );
  }

  asTransaction(
    signedTxHash: string,
    nonce: bigint | undefined,
    wallet: Wallet,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadataMap?: Map<TxOrUserOpMetadataKey, any>,
    _txSubmissionType?: TxSubmissionType,
    type?: TxFlowOrType,
  ): TxOrUserOp {
    return new TxOrUserOp({
      id: v4(),
      createdAt: new Date(),
      confirmedAt: undefined,
      blockchain: this.blockchain,
      currencyCode: this.currencyCode,
      feeCurrencyCode: this.feeCurrencyCode,
      feeCurrencyDecimal: this.feeCurrencyDecimal,
      toAddress: this.recipientAddress,
      toDomain: this.recipientDomain,
      fromAddress: this.fromAddress,
      fromDomain: this.fromDomain,
      amount: this.transferValue,
      fee: this.estimatedFee,
      state: TxState.PENDING,
      metadata: new TxOrUserOpMetadata(metadataMap),
      network: this.network,
      txHash: signedTxHash,
      txOrUserOpHash: signedTxHash,
      userOpHash: undefined,
      walletIndex: wallet.selectedIndex ?? 0n,
      accountId: wallet.accountId,
      isSent: true,
      contractAddress: wallet.contractAddress,
      tokenName: wallet.displayName,
      tokenDecimal: wallet.decimals,
      walletId: wallet.id,
      nonce,
      type,
    });
  }
}
