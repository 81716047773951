export class Get1559GasPricesError extends Error {
  name = 'Get1559GasPricesError';
}

export class PaymasterSignatureError extends Error {
  name = 'PaymasterSignatureError';
}

export class PaymasterStubError extends Error {
  name = 'PaymasterStubError';
}

export class InsufficientFundsForGasError extends Error {
  name = 'insufficientFundsForGasError';
}

export class InsufficientFundsForTransactionError extends Error {
  name = 'insufficientFundsForTransactionError';
}
