/**
 * These buffers are adjustable in case our provider changes their gas estimation heuristics
 */
export const VERIFICATION_GAS_BUFFER = BigInt(500_000);
export const PRE_VERIFICATION_GAS_MULTIPLIER = BigInt(2);

export function adjustEstimatedUserOpGas(data: {
  preVerificationGas: bigint;
  verificationGasLimit: bigint;
  callGasLimit: bigint;
}) {
  return {
    preVerificationGas: data.preVerificationGas * PRE_VERIFICATION_GAS_MULTIPLIER,
    verificationGasLimit: data.verificationGasLimit + VERIFICATION_GAS_BUFFER,
    callGasLimit: data.callGasLimit,
  };
}
