import { ActionType, AnalyticsEventImportance, ComponentType } from '@cbhq/client-analytics';

import { EventLoggerParams, logDataEvent, logEvent, useLogEventOnMount } from '../utils/log';

export const PaymentMethods = {
  WALLET: 'wallet',
  COINBASE: 'coinbase',
} as const;

export type PaymentMethod = (typeof PaymentMethods)[keyof typeof PaymentMethods];

export type SDKRequestMetadata = {
  type?: 'action' | 'error';
  requestId?: string;
  requestMethod?: string;
  dappOrigin?: string;
  dappName?: string;
  dappSdkVersion?: string;
  dappSignerPreference?: 'all' | 'smartWalletOnly' | 'eoaOnly';
  routedFrom?: 'extension' | 'sdk';
  activeChainId?: number;
  activeChainName?: string;
};

export function triggerSignAndSendStart(
  sdkRequestMetadata: SDKRequestMetadata,
  { paymentMethod }: { paymentMethod: PaymentMethod },
) {
  logDataEvent(
    'transaction_send_and_sign_start',
    {
      data: { ...sdkRequestMetadata, paymentMethod },
    },
    AnalyticsEventImportance.high,
  );
}

export function triggerSignAndSendSuccess(
  sdkRequestMetadata: SDKRequestMetadata,
  {
    paymentMethod,
    magicSpendFlow,
    paymasterURL,
  }: { paymentMethod: PaymentMethod; magicSpendFlow?: string; paymasterURL?: string },
) {
  logDataEvent(
    'transaction_send_and_sign_success',
    {
      data: { ...sdkRequestMetadata, paymentMethod, magicSpendFlow, paymasterURL },
    },
    AnalyticsEventImportance.high,
  );
}

type AddBaseCurrencyEventProps = Pick<EventLoggerParams, 'asset' | 'network' | 'flow'>;

export function triggerAddBaseCurrencyButtonClicked(props: AddBaseCurrencyEventProps) {
  return logDataEvent('transaction_add_base_asset_button_clicked', {
    action: ActionType.click,
    componentType: ComponentType.button,
    ...props,
  });
}

export function triggerSendUserOpStart(sdkRequestMetadata: SDKRequestMetadata) {
  logDataEvent('transaction_send_user_op_start', {
    data: { ...sdkRequestMetadata },
  });
}

export function triggerSendUserOpFailed(
  sdkRequestMetadata: SDKRequestMetadata,
  { details, name, docsPath }: { details?: string; name?: string; docsPath?: string },
) {
  logDataEvent('transaction_send_user_op_failed', {
    data: { ...sdkRequestMetadata, details, name, docsPath },
  });
}

export function triggerSendUserOpSuccess(sdkRequestMetadata: SDKRequestMetadata) {
  logDataEvent('transaction_send_user_op_success', {
    data: { ...sdkRequestMetadata },
  });
}

export function triggerSignAndSendFailed(
  sdkRequestMetadata: SDKRequestMetadata,
  {
    failureReason,
    code,
    paymentMethod,
  }: {
    failureReason?: string;
    name?: string;
    code?: number;
    paymentMethod: PaymentMethod;
  },
) {
  logDataEvent('transaction_send_and_sign_failure', {
    data: { failureReason, code, ...sdkRequestMetadata, paymentMethod },
  });
}

export type UnsignedUserOpErrorType =
  | 'nonceError'
  | 'gasPriceError'
  | 'userOpGasError'
  | 'byteCodeError'
  | 'paymasterError';

export function triggerUseUnsignedUserOpError(
  sdkRequestMetadata: SDKRequestMetadata,
  {
    type,
    code,
    message,
    name,
    docsPath,
  }: {
    type: UnsignedUserOpErrorType;
    message?: string;
    code?: number | string;
    name?: string;
    docsPath?: string;
  },
) {
  logDataEvent('transaction_unsigned_user_op_error', {
    data: { type, code, message, name, docsPath, ...sdkRequestMetadata },
  });
}

export function triggerUnknownUnsignedUserOpError(error: Error) {
  logDataEvent('transaction_unsigned_user_op_unknown_error', {
    errorMessage: error.message,
  });
}

export function logUnsignedUserOpSuccess() {
  logDataEvent('transaction.unsigned_user_op.success', {});
}

export function triggerUserDeniedTransaction(sdkRequestMetadata: SDKRequestMetadata) {
  logEvent('transaction_user_denied', {
    data: sdkRequestMetadata,
    action: ActionType.click,
    componentType: ComponentType.button,
  });
}

export function logTransactionReviewViewed(sdkRequestMetadata: SDKRequestMetadata) {
  return logEvent('transaction_review_viewed', {
    data: sdkRequestMetadata,
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logCallsReviewViewed(sdkRequestMetadata: SDKRequestMetadata) {
  return logEvent('calls_review_viewed', {
    data: sdkRequestMetadata,
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logTransactionReceiptViewed(
  sdkRequestMetadata: SDKRequestMetadata,
  { txHash }: { txHash?: string },
) {
  return logEvent('transaction_receipt_viewed', {
    data: { txHash, ...sdkRequestMetadata },
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logCallsSubmittedViewed(
  sdkRequestMetadata: SDKRequestMetadata,
  { callsId }: { callsId?: string },
) {
  return logEvent('calls_submitted_viewed', {
    data: { callsId, ...sdkRequestMetadata },
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logSignBoundaryViewed(sdkRequestMetadata: SDKRequestMetadata) {
  return logEvent('sign_boundary_viewed', {
    data: sdkRequestMetadata,
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logSDKRequestRouterViewed(sdkRequestMetadata: SDKRequestMetadata) {
  return logEvent('sdk_request_router_viewed', {
    data: sdkRequestMetadata,
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

// Note: eoa_signer is a deprecated type that refers to a recovery key signer
//       The function names have been updated but the underlying event name
//       remains the same for posterity
export function logAddRecoveryKeySignerStart() {
  return logDataEvent('add_eoa_signer_start', {});
}

export function logAddRecoveryKeySignerSuccess() {
  return logDataEvent('add_eoa_signer_success', {});
}

export function logAddRecoveryKeySignerFailure({ failureReason }: { failureReason: string }) {
  return logDataEvent('add_eoa_signer_failures', {
    data: { failureReason },
  });
}

export function logSDKRequestTimedOut({
  sdkRequestMetadata,
  isWindowOpenerDefined,
}: {
  sdkRequestMetadata: SDKRequestMetadata;
  isWindowOpenerDefined: boolean;
}) {
  return logDataEvent('sdk_request_timed_out', {
    data: {
      ...sdkRequestMetadata,
      isWindowOpenerDefined,
    },
  });
}

type UseLogTransactionBannerViewedProps = {
  bannerId: string;
};

export function useLogTransactionBannerViewed({ bannerId }: UseLogTransactionBannerViewedProps) {
  useLogEventOnMount('transaction.banner.viewed', {
    componentType: ComponentType.banner,
    action: ActionType.view,
    data: { bannerId },
  });
}

export function logTransactionBannerDismissed({ bannerId }: UseLogTransactionBannerViewedProps) {
  logEvent('transaction.banner.dismissed', {
    componentType: ComponentType.banner,
    action: ActionType.dismiss,
    data: { bannerId },
  });
}

export function logPaymentMethodSelected({
  paymentMethod,
  isConnected,
}: {
  paymentMethod: PaymentMethod;
  isConnected: boolean;
}) {
  logEvent('transaction.payment_method.selected', {
    componentType: ComponentType.banner,
    action: ActionType.dismiss,
    data: { paymentMethod, isConnected },
  });
}

export function logConfirmedWithNoUserOperation() {
  logEvent('transaction.confirmed_with_no_user_operation', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}

export function useLogTransactionLinkDisclaimerViewed() {
  useLogEventOnMount('transaction_link.disclaimer.viewed', {
    componentType: ComponentType.page,
    action: ActionType.view,
  });
}

export function logTransactionLinkDisclaimerDismissed() {
  logEvent('transaction_link.disclaimer.dismissed', {
    componentType: ComponentType.button,
    action: ActionType.click,
  });
}
