import { accountAbi } from 'cb-wallet-data/scw/libs/wagmi/generated';
import { encodeFunctionData, Hex } from 'viem';

import { Call } from '../types';

export function buildUserOpCallDataForExecuteBatch({ calls }: { calls: Call[] }): Hex {
  return encodeFunctionData({
    abi: accountAbi,
    functionName: 'executeBatch',
    args: [calls],
  });
}
