import { GetBalanceReturnType } from '@wagmi/core';
import { PaymentMethod, PaymentMethods } from 'cb-wallet-analytics/scw/Transactions';
import {
  Call,
  PaymasterService,
  UnsignedUserOp,
} from 'cb-wallet-data/scw/features/sign/transaction/types';
import { buildUserOpCallDataForExecuteBatch } from 'cb-wallet-data/scw/features/sign/transaction/utils/buildUserOpCallDataForExecuteBatch';
import { getGasCostForUserOp } from 'cb-wallet-data/scw/features/sign/transaction/utils/getGasCostForUserOp';
import { Hex } from 'viem';

export function getCallDataForMaxSend({
  balances,
  calls,
  chainId,
  isMaxSendApplicable,
  paymasterAndData,
  paymasterService,
  paymentMethod,
  unsignedUserOpWithUserOpGasEstimation,
}: {
  balances?: Record<number, GetBalanceReturnType>;
  calls: Call[];
  chainId: number;
  isMaxSendApplicable: boolean;
  paymasterAndData?: Hex;
  paymasterService?: PaymasterService;
  paymentMethod: PaymentMethod;
  unsignedUserOpWithUserOpGasEstimation?: UnsignedUserOp;
}) {
  // generate max send calldata if:
  // 1. isMaxSendApplicable is true
  // 2. paymaster signature is not defined
  // 3. paymaster url was not provided by the dapp
  // 4. payment method is Wallet
  // 5. only one userOp has a value associated with it
  // 6. the value of the userOp matches perfectly with the user's total balance
  // 7. the balance of the wallet is higher than the cost of gas
  if (
    !isMaxSendApplicable ||
    paymasterAndData ||
    paymasterService?.paymasterURL ||
    paymentMethod !== PaymentMethods.WALLET
  ) {
    return undefined;
  }

  const hasOnlyOneCallWithValue = calls.filter((call) => call.value > 0n).length === 1;
  if (!hasOnlyOneCallWithValue) {
    return undefined;
  }

  const callWithValue = calls.find((call) => call.value > 0n);
  if (!callWithValue) {
    return undefined;
  }

  const gasCost = getGasCostForUserOp(unsignedUserOpWithUserOpGasEstimation);
  const balance = balances?.[chainId];
  if (!balance) {
    return undefined;
  }

  // is a valid max send if the value of the userOp matches the balance and the balance is higher than the cost of gas
  if (balance.value === callWithValue.value && balance.value > gasCost) {
    const newCalls = calls.map((call) =>
      call === callWithValue ? { ...callWithValue, value: balance.value - gasCost } : call,
    );

    return buildUserOpCallDataForExecuteBatch({ calls: newCalls });
  }
}
