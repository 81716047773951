import { isUTXOBasedBlockchain } from 'cb-wallet-data/chains/blockchains';
import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import {
  SignAndSubmitArguments,
  SignAndSubmitReturn,
} from 'cb-wallet-data/stores/Transactions/methods/signAndSubmitTransaction';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { TransactionError } from 'cb-wallet-data/stores/Transactions/TransactionError';
import { v4 } from 'uuid';

import { save } from '../database';
import { UTXOSignedTx } from '../models/UTXOSignedTx';
import { UTXOUnsignedTx } from '../models/UTXOUnsignedTx';

export async function signAndSubmitTransaction({
  unsignedTxOrUserOp,
  wallet,
  onSubmit,
  txSubmissionType: _txSubmissionType,
}: SignAndSubmitArguments): SignAndSubmitReturn {
  if (!(unsignedTxOrUserOp as UTXOUnsignedTx).coinSelection) {
    throw TransactionError.UnknownTransactionType;
  }

  const blockchainSymbol = wallet.blockchain.rawValue;

  if (!isUTXOBasedBlockchain(blockchainSymbol)) {
    throw new Error(`Invalid blockchain symbol ${blockchainSymbol} for utxo configuration`);
  }

  const { blockchain, currencyCode, network, coinSelection } = unsignedTxOrUserOp as UTXOUnsignedTx;
  const addressesStr = coinSelection.inputs.map((input) => input.address);
  const signAndSubmitUTXOTransaction = getSigningOrDerivationMethodForAccount(
    wallet.accountId,
    'signAndSubmitUTXOTransaction',
  );

  const { data: signedTxData, hash: txHash } = await signAndSubmitUTXOTransaction({
    accountId: wallet.accountId,
    blockchain,
    currencyCode,
    addressesStr,
    network,
    wallet,
    coinSelection,
  });

  const signedTx = new UTXOSignedTx({
    id: v4(),
    signedTransactionData: signedTxData,
    txHash,
    state: TxState.PENDING,
    network,
    currencyCode,
    notFoundCount: 0n,
    blockchain,
  });

  await save(signedTx);

  onSubmit(signedTx);

  return signedTx;
}
