import { Blockchain } from 'cb-wallet-data/models/Blockchain';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { Call } from 'cb-wallet-data/scw/features/sign/transaction/types';
import { getGasCostForUserOp } from 'cb-wallet-data/scw/features/sign/transaction/utils/getGasCostForUserOp';
import { getGasCostLowerBoundForUserOp } from 'cb-wallet-data/scw/features/sign/transaction/utils/getGasCostLowerBoundForUserOp';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';
import { TxFlowOrType, TxOrUserOp } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOp';
import { TxOrUserOpMetadata } from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadata';
import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataValue,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { TxState } from 'cb-wallet-data/stores/Transactions/models/TxState';
import { TxSubmissionType } from 'cb-wallet-data/stores/Transactions/models/TxSubmissionType';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { v4 } from 'uuid';
import { Hex } from 'viem';

import { EthUnsignedUserOp } from './EthUnsignedUserOp';

export type CreateUserOpArgs = {
  baseFeePerGas: bigint;
  calls: Call[];
  callData: Hex;
  callGasLimit: bigint;
  initCode: Hex;
  paymasterAndData: Hex;
  preVerificationGas: bigint;
  verificationGasLimit: bigint;
  maxFeePerGas: bigint;
  maxPriorityFeePerGas: bigint;
  nonce: bigint;
  recipientAddress: string;
  fromAddress: string;
  transferValue: bigint;
  blockchain: Blockchain;
  currencyCode: CurrencyCode;
  feeCurrencyCode: CurrencyCode;
  feeCurrencyDecimal: bigint;
  metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>;
  fromDomain: string | undefined;
  recipientDomain: string | undefined;
  network: Network;
};

export class EthereumUnsignedUserOp implements EthUnsignedUserOp {
  // eslint-disable-next-line max-params
  constructor(
    readonly baseFeePerGas: bigint,
    readonly calls: Call[],
    readonly callData: Hex,
    readonly callGasLimit: bigint,
    readonly initCode: Hex,
    readonly paymasterAndData: Hex,
    readonly preVerificationGas: bigint,
    readonly verificationGasLimit: bigint,
    readonly maxFeePerGas: bigint,
    readonly maxPriorityFeePerGas: bigint,
    readonly nonce: bigint,
    readonly recipientAddress: string,
    readonly fromAddress: string,
    readonly transferValue: bigint,
    readonly blockchain: Blockchain,
    readonly currencyCode: CurrencyCode, // could be omitted in favor of info in calls
    readonly feeCurrencyCode: CurrencyCode,
    readonly feeCurrencyDecimal: bigint,
    readonly metadata: Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>,
    readonly fromDomain: string | undefined,
    readonly recipientDomain: string | undefined,
    readonly network: Network,
  ) {}

  get estimatedFee(): bigint {
    return getGasCostForUserOp(this);
  }

  get lowerBoundFee(): bigint {
    return getGasCostLowerBoundForUserOp({
      callGasLimit: this.callGasLimit,
      verificationGasLimit: this.verificationGasLimit,
      preVerificationGas: this.preVerificationGas,
      maxFeePerGas: this.maxFeePerGas,
      maxPriorityFeePerGas: this.maxPriorityFeePerGas,
      additionalParameters: { baseFeePerGas: this.baseFeePerGas },
    });
  }

  get upperBoundFee(): bigint {
    return getGasCostForUserOp(this);
  }

  /**
   * Custom constructor that generates an ethereum unsigned user op
   * @param {CreateUserOpArgs} params
   * @returns {EthereumUnsignedUserOp}
   */
  static createUserOp({
    baseFeePerGas,
    calls,
    callData,
    callGasLimit,
    initCode,
    paymasterAndData,
    preVerificationGas,
    verificationGasLimit,
    maxFeePerGas,
    maxPriorityFeePerGas,
    nonce,
    recipientAddress,
    fromAddress,
    transferValue,
    blockchain,
    currencyCode,
    feeCurrencyCode,
    feeCurrencyDecimal,
    metadata,
    fromDomain,
    recipientDomain,
    network,
  }: CreateUserOpArgs): EthereumUnsignedUserOp {
    return new EthereumUnsignedUserOp(
      baseFeePerGas,
      calls,
      callData,
      callGasLimit,
      initCode,
      paymasterAndData,
      preVerificationGas,
      verificationGasLimit,
      maxFeePerGas,
      maxPriorityFeePerGas,
      nonce,
      recipientAddress,
      fromAddress,
      transferValue,
      blockchain,
      currencyCode,
      feeCurrencyCode,
      feeCurrencyDecimal,
      metadata,
      fromDomain,
      recipientDomain,
      network,
    );
  }

  asTransaction(
    signedUserOpHash: string,
    nonce: bigint | undefined,
    wallet: Wallet,
    metadataMap?: Map<TxOrUserOpMetadataKey, string | number | boolean | bigint>,
    _?: TxSubmissionType,
    type?: TxFlowOrType,
  ): TxOrUserOp {
    return new TxOrUserOp({
      id: v4(),
      createdAt: new Date(),
      blockchain: this.blockchain,
      currencyCode: this.currencyCode,
      feeCurrencyCode: this.feeCurrencyCode,
      feeCurrencyDecimal: this.feeCurrencyDecimal,
      toAddress: this.recipientAddress,
      toDomain: this.recipientDomain,
      fromAddress: this.fromAddress,
      fromDomain: this.fromDomain,
      amount: this.transferValue,
      fee: this.upperBoundFee,
      state: TxState.PENDING,
      metadata: new TxOrUserOpMetadata(metadataMap),
      network: this.network,
      txOrUserOpHash: signedUserOpHash,
      userOpHash: signedUserOpHash,
      txHash: undefined,
      accountId: wallet.accountId,
      walletIndex: wallet.selectedIndex ?? 0n,
      isSent: true,
      contractAddress: wallet.contractAddress,
      tokenName: wallet.displayName,
      tokenDecimal: wallet.decimals,
      walletId: wallet.id,
      nonce,
      type,
      transfers: [],
      deleted: false,
    });
  }
}
