import { SendOptions, Transaction, VersionedTransaction } from '@solana/web3.js';
import { getSigningOrDerivationMethodForAccount } from 'cb-wallet-data/ServiceLocator/signingAndDerivation/utils/getSigningOrDerivationMethodForAccount';
import { TransactionError } from 'cb-wallet-data/stores/Transactions/TransactionError';

import { solanaAddressDerivationPath } from '../config';
/**
 * Sign transaction using mnemonic, default to index 0
 * @param unsignedTx
 * @param accountId
 * @param skipSubmit
 * @param sendOptions
 * @param walletIndex
 * @return a Buffer of signed transaction
 */
export async function signAndSubmitSolanaTransaction(
  unsignedTx: Transaction | VersionedTransaction,
  accountId: string,
  skipSubmit: boolean,
  sendOptions?: SendOptions,
  walletIndex?: bigint,
): Promise<{ data: Buffer; hash?: string }> {
  const derivationPath = solanaAddressDerivationPath(walletIndex || 0n);
  if (unsignedTx instanceof Transaction) {
    const signAndSubmitSolanaTx = getSigningOrDerivationMethodForAccount(
      accountId,
      'signAndSubmitSolanaTransaction',
    );

    return signAndSubmitSolanaTx({
      accountId,
      derivationPath,
      solanaTx: unsignedTx,
      skipSubmit,
      sendOptions,
    });
  }

  if (unsignedTx instanceof VersionedTransaction) {
    const signAndSubmitSolanaVersionedTx = getSigningOrDerivationMethodForAccount(
      accountId,
      'signAndSubmitSolanaVersionedTransaction',
    );

    return signAndSubmitSolanaVersionedTx({
      accountId,
      derivationPath,
      solanaTx: unsignedTx,
      skipSubmit,
      sendOptions,
    });
  }

  throw TransactionError.UnknownTransactionType;
}
