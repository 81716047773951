import { decodeERC20Transfer } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/decodeERC20Transfer';
import { coerceError } from 'cb-wallet-data/errors/reportError';
import { Call } from 'cb-wallet-data/scw/features/sign/transaction/types';
import {
  generateUnsignedUserOpBase,
  GenerateUnsignedUserOpParamsBase,
  GenerateUnsignedUserOpReturnError,
  GenerateUnsignedUserOpReturnSuccessType,
} from 'cb-wallet-data/scw/features/sign/transaction/utils/generateUnsignedUserOpBase';
import { fetchDomains } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddress';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import {
  GenerateUnsignedTransactionArgs,
  GenerateUnsignedTransactionResult,
} from 'cb-wallet-data/stores/Transactions/methods/generateUnsignedTransaction';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

import { EthereumWalletConfiguration } from '../config';
import { EthereumChain } from '../EthereumChain';
import { EthereumUnsignedUserOp } from '../models/EthereumUnsignedUserOp';

import { EthUserOpConfig } from './chainConfigs';

/**
 * The transferValue is something we calculate here to conform to the shape of UnsignedTxOrUserOp - only for the send flow.
 *
 * It's not used in the signing of the user op itself but rather is an implementation detail
 * of send where the send flow relies on this value to show how much is being sent. This amount doesn't
 * really make sense for a user op (which could have multiple transfers). Hence, we only
 * include a value here if there is a single transfer happening in this user op (which in the native send flow
 * is the case).
 * 1. Compute how much native token is being transferred
 * 2. Compute how much erc20 tokens are being transferred
 *
 * If there is a single transfer from 1 & 2, use that transferValue. Otherwise, use 0n.
 *
 * Note: this method is only exposed for testing.  If you find yourself using this method,
 * you're probably doing something wrong.
 */
export function computeTransferValueForNativeSendFlow(
  calls: Call[],
  wallet: Wallet,
  recipientAddress: string,
): bigint {
  // The native send flow wouldn't create a user op with no calls or more than 1 call
  if (calls.length === 0 || calls.length > 1) {
    return 0n;
  }

  const call = calls[0];

  if (call.value > 0n && call.data !== '0x') {
    // This is a native send with call data, this shouldn't happen in our native send flows
    return 0n;
  }

  if (call.value > 0n) {
    return call.value;
  }

  if (call.target.toLowerCase() === wallet.contractAddress?.toLowerCase()) {
    try {
      const decodedTransfer = decodeERC20Transfer(call.data);
      if (recipientAddress.toLowerCase() === decodedTransfer?.toAddress?.toLowerCase()) {
        return decodedTransfer?.value;
      }
    } catch (err: ErrorOrAny) {
      // Not an erc20 transfer, or data is invalid.
    }
  }
  return 0n;
}

export type GenerateUnsignedUserOpParams = Omit<
  GenerateUnsignedTransactionArgs,
  'transactionAmount'
>;

export async function generateUnsignedUserOp({
  wallet,
  recipientAddress,
  metadata,
  txOrUserOpConfig,
  isSponsoredTx,
}: GenerateUnsignedUserOpParams): GenerateUnsignedTransactionResult {
  if (isSponsoredTx) {
    throw new Error('Sponsored transactions are not supported for UserOps - use paymaster instead');
  }

  const config = txOrUserOpConfig as EthUserOpConfig;

  const { primaryAddress: fromAddress, network, currencyCode, blockchain } = wallet;

  const ethereumChain = network.asChain() as EthereumChain;

  if (!ethereumChain) throw NetworkError.invalidNetwork(network);

  const feeCurrencyCode = EthereumWalletConfiguration.currencyCodeForNetwork(network);
  const feeCurrencyDecimal = EthereumWalletConfiguration.feeDecimalForNetwork(network);

  const scwParams: GenerateUnsignedUserOpParamsBase = {
    chainId: ethereumChain.chainId,
    sender: fromAddress as `0x${string}`,
    nonceKey: config.nonceKey ?? 0n,
    paymentMethod: config.paymentMethod,
    dappOrigin: config.dappOrigin,
    scwUrl: 'https://keys.coinbase.com', // TODO add this to config or env
    calls: config.calls,
    callData: config.callData,
    paymasterService: config.paymasterService,
    initialPasskeySigner: config.initialPasskeySigner,
    accountFactoryAddress: config.accountFactoryAddress,
    isMaxSendApplicable: config.isMaxSendApplicable,
    isPaymasterApplicable: config.isPaymasterApplicable,
  };

  try {
    const userOpResult = await generateUnsignedUserOpBase(scwParams);

    const userOpSuccessResult = userOpResult as Omit<
      GenerateUnsignedUserOpReturnSuccessType,
      'type'
    >;

    const [fromDomain, recipientDomain] = await fetchDomains([fromAddress, recipientAddress]);

    // See documentation of this method but transfer value here is only relevant to the send flow
    const transferValue = computeTransferValueForNativeSendFlow(
      config.calls,
      wallet,
      recipientAddress,
    );

    const ethUnsignedUserOp = EthereumUnsignedUserOp.createUserOp({
      baseFeePerGas: userOpSuccessResult.additionalParameters?.baseFeePerGas,
      calls: config.calls,
      callData: userOpSuccessResult.callData,
      callGasLimit: userOpSuccessResult.callGasLimit,
      initCode: userOpSuccessResult.initCode,
      paymasterAndData: userOpSuccessResult.paymasterAndData,
      preVerificationGas: userOpSuccessResult.preVerificationGas,
      verificationGasLimit: userOpSuccessResult.verificationGasLimit,
      maxFeePerGas: userOpSuccessResult.maxFeePerGas,
      maxPriorityFeePerGas: userOpSuccessResult.maxPriorityFeePerGas,
      nonce: userOpSuccessResult.nonce,
      fromAddress: fromAddress as `0x${string}`,
      transferValue, // This is used in the send UI
      blockchain,
      currencyCode,
      feeCurrencyCode,
      feeCurrencyDecimal: feeCurrencyDecimal ?? 0n,
      metadata,
      fromDomain,
      recipientDomain,
      recipientAddress,
      network,
    });

    return {
      kind: 'Success',
      transaction: ethUnsignedUserOp,
      wallet,
      err: undefined,
    };
  } catch (err: ErrorOrAny) {
    const rawError = Object.values((err as GenerateUnsignedUserOpReturnError).errorData).find(
      (error) => Boolean(error),
    )!;

    return {
      kind: 'Failure',
      wallet,
      err: coerceError(rawError, 'generateUnsignedUserOp'), // TODO - make sure we're not dropping error context here
    };
  }
}
