// eslint-disable-next-line no-restricted-imports
import { standardErrors } from '@coinbase/wallet-sdk/dist/core/error/errors';
import { Hex } from 'viem';

export const USER_CANCELLED_ERROR = standardErrors.provider.userRejectedRequest(
  'User cancelled transaction',
);

export const USER_OP_RECEIPT_POLL_INTERVAL = 200;

/**
 * Read more about dummy values here: https://www.alchemy.com/blog/dummy-signatures-and-gas-token-transfers
 */
export const DEPLOYED_INIT_CODE = '0x';
export const DUMMY_SIGNATURE: Hex =
  '0x0000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000c0000000000000000000000000000000000000000000000000000000000000012000000000000000000000000000000000000000000000000000000000000000170000000000000000000000000000000000000000000000000000000000000001949fc7c88032b9fcb5f6efc7a7b8c63668eae9871b765e23123bb473ff57aa831a7c0d9276168ebcc29f2875a0239cffdf2a9cd1c2007c5c77c071db9264df1d000000000000000000000000000000000000000000000000000000000000002549960de5880e8c687434170f6476605b8fe4aeb9a28632c7995cf3ba831d97630500000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000008a7b2274797065223a22776562617574686e2e676574222c226368616c6c656e6765223a2273496a396e6164474850596759334b7156384f7a4a666c726275504b474f716d59576f4d57516869467773222c226f726967696e223a2268747470733a2f2f7369676e2e636f696e626173652e636f6d222c2263726f73734f726967696e223a66616c73657d00000000000000000000000000000000000000000000';
export const DUMMY_PAYMASTER_DATA: Hex = '0x';
export const DUMMY_USEROP_GAS_DATA = 0n;

/**
 * When we regenerate the user op, we treat the following data as fresh for 120s.
 * - Byte code
 * - Max fee per gas
 * - Nonce
 *
 * This is because fetching these things tends to slow the user op generation process down.
 */
export const USER_OP_STALE_TIME_FOR_USER_OP_GENERATION = 120000;

export enum ConnectionType {
  SCW = 'scw_connection_type',
  EXTENSION = 'extension_connection_type',
}
