import { getUnminedSignedTxs } from 'cb-wallet-data/chains/AccountBased/Ethereum/database';
import { EthereumChain } from 'cb-wallet-data/chains/AccountBased/Ethereum/EthereumChain';
import { EthereumError } from 'cb-wallet-data/chains/AccountBased/Ethereum/exceptions/EthereumExceptions';
import { EthereumSignedTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import { EthereumUnsignedTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumUnsignedTx';
import { NetworkError } from 'cb-wallet-data/stores/Networks/NetworkError';
import { firstOrUndefined } from 'cb-wallet-data/utils/Array';
/**
 * Check that the nonce within the provided [tx] is valid, or calculate the next nonce.
 *
 * @param tx The transaction to check nonce validity
 * @param confirmedTxCount The number of confirmed transactions
 * @param signedTransactions A [List] of [SignedEthereumTx]
 * @param skipNonceCheck `true` when we want to set nonce manually without any validations, `false` otherwise.
 *                        This is used when generating multiple signed tx hashes to get gas estimates in a local
 *                        fork of mainnet.
 *
 * @return The next valid nonce
 */
export async function calculateNextNonce(
  tx: EthereumUnsignedTx,
  confirmedTxCount: bigint,
  signedTransactions: EthereumSignedTx[],
  skipNonceCheck = false,
): Promise<bigint> {
  if (tx.nonce === undefined || tx.nonce === 0n) {
    return Promise.resolve(nextNonce(confirmedTxCount, signedTransactions));
  }

  const nonce = tx.nonce;
  const ethereumChain = tx.network.asChain() as EthereumChain;

  if (!ethereumChain) {
    throw NetworkError.invalidNetwork(tx.network);
  }

  const unminedSignedTxs = await getUnminedSignedTxs(ethereumChain);

  // Check if a transaction with the same nonce and chainId exists as the current one
  const unminedTx = firstOrUndefined(
    unminedSignedTxs.filter(
      (filterTx: EthereumSignedTx) =>
        filterTx.nonce === nonce && filterTx.chainId === BigInt(ethereumChain.chainId),
    ),
  );

  if (skipNonceCheck) {
    return nonce;
  }

  const expectedNextNonce = nextNonce(confirmedTxCount, signedTransactions);

  // 1. If a transaction with the same nonce and chainId exists, then this nonce is valid
  if (unminedTx) {
    return nonce;
  }

  // If 1. is false, then check if the next nonce is still the same or less than the one we just
  // calculated previously to fetching unconfirmed transactions, if so, then this nonce still is
  // valid with the same nonce. We shouldn't do this unless we intend to overwrite the previous
  // transaction with a higher gas cost.  A nonce must always be >= than the confirmed tx count
  // else, we have gotten an incorrect nonce
  if (nonce < confirmedTxCount || nonce > expectedNextNonce) {
    throw EthereumError.incorrectNonceProvided;
  }

  return nonce;
}

export function nextNonce(confirmedTxCount: bigint, pendingSignedTxs: EthereumSignedTx[]): bigint {
  // Find the signed transaction which has the maximum nonce
  // If none exists, just return the confirmed tx count as the nonce
  const pendingTxNonces = pendingSignedTxs.map((pendingTx) => Number(pendingTx.nonce));

  if (pendingTxNonces.length === 0) {
    return confirmedTxCount;
  }

  const maxPendingSignedTxNonce = BigInt(Math.max(...pendingTxNonces));

  // Choose the greater between the number of confirmed transactions
  // and the maximum nonce found in unconfirmed transactions
  // If confirmedTxCount is greater, do not increment by one, because nonce is 0 based while tx count is 1 based
  // If maxUnconfirmedTxNonce is greater, increment by one
  // eslint-ignore @typescript-eslint/restrict-plus-operands
  const nonce =
    confirmedTxCount > maxPendingSignedTxNonce ? confirmedTxCount : maxPendingSignedTxNonce + 1n;

  return nonce;
}
