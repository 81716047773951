import { queryClient } from 'cb-wallet-data/QueryProvider';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { readEntryPointGetNonce } from 'cb-wallet-data/scw/libs/wagmi/generated';
import { stringify } from 'cb-wallet-store/utils/serialization';

type QueryParams = {
  chainId: number;
  sender: `0x${string}`;
  nonceKey: bigint;
};

export async function queryEntryPointGetNonce({ chainId, sender, nonceKey }: QueryParams) {
  return queryClient.fetchQuery(
    ['readEntryPointGetNonce', stringify({ chainId, sender, nonceKey })],
    async () => readEntryPointGetNonce(getConfig(), { chainId, args: [sender, nonceKey] }),
    { staleTime: 0 },
  );
}
