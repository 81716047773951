import { EthereumSignedTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/models/EthereumSignedTx';
import { signAndSubmit1559Transaction } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/signAndSubmit1559Transaction';
import { signAndSubmitTransaction } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/signAndSubmitLegacyTransaction';
import { isETH1559Tx } from 'cb-wallet-data/chains/AccountBased/Ethereum/utils/isETH1559Tx';
import { isETHLegacyTx } from 'cb-wallet-data/chains/AccountBased/Ethereum/utils/isETHLegacyTx';
import { cbReportError } from 'cb-wallet-data/errors/reportError';
import {
  SignAndSubmitArguments,
  SignAndSubmitReturn,
} from 'cb-wallet-data/stores/Transactions/methods/signAndSubmitTransaction';
import { TransactionError } from 'cb-wallet-data/stores/Transactions/TransactionError';
import { Tracer } from 'cb-wallet-data/Tracer/classes/Tracer';

import { insertSignedTransaction } from '../database';
import { EthereumError } from '../exceptions/EthereumExceptions';
import { isETHUserOp } from '../utils/isETHUserOp';

import { EthereumSignedUserOp, signAndSubmitUserOp } from './signAndSubmitUserOp';

export async function signAndSubmit({
  unsignedTxOrUserOp,
  wallet,
  txSubmissionType: _txSubmissionType,
  onSubmit,
  skipSubmit,
  skipPrepare,
  isMEVProtectionEnabled = false,
}: SignAndSubmitArguments): SignAndSubmitReturn {
  let signedTxOrUserOp: EthereumSignedTx | EthereumSignedUserOp;

  const tracer = new Tracer('sign_submit_ethereum');

  if (isETHUserOp(unsignedTxOrUserOp)) {
    tracer.startSpan('sign_userop');
    signedTxOrUserOp = await signAndSubmitUserOp({
      userOp: unsignedTxOrUserOp,
      accountId: wallet.accountId,
      skipSubmit,
      skipPrepare,
    });
    tracer.endSpan('sign_userop');
  } else if (isETHLegacyTx(unsignedTxOrUserOp)) {
    tracer.startSpan('sign_transaction');
    signedTxOrUserOp = await signAndSubmitTransaction({
      tx: unsignedTxOrUserOp,
      accountId: wallet.accountId,
      skipNonceCheck: unsignedTxOrUserOp.nonce !== undefined,
      skipSubmit,
      skipPrepare,
      isMEVProtectionEnabled,
    });
    tracer.endSpan('sign_transaction');
  } else if (isETH1559Tx(unsignedTxOrUserOp)) {
    tracer.startSpan('sign1559_transaction');
    signedTxOrUserOp = await signAndSubmit1559Transaction({
      tx: unsignedTxOrUserOp,
      accountId: wallet.accountId,
      skipNonceCheck: unsignedTxOrUserOp.nonce !== undefined,
      skipSubmit,
      skipPrepare,
      isMEVProtectionEnabled,
    });
    tracer.endSpan('sign1559_transaction');
  } else {
    // do not call tracer.done(); as this would lead to an inaccurate measurement
    // of performance until further metadata can be added and filtered by
    throw TransactionError.UnknownTransactionType;
  }
  if (skipSubmit) {
    tracer.done();
    return signedTxOrUserOp;
  }

  tracer.startSpan('insert_signed_transaction');
  let submittedTx;

  if (signedTxOrUserOp instanceof EthereumSignedTx) {
    submittedTx = await insertSignedTransaction(signedTxOrUserOp);
  } else if (signedTxOrUserOp instanceof EthereumSignedUserOp) {
    submittedTx = signedTxOrUserOp;
  }
  tracer.endSpan('insert_signed_transaction');

  if (!submittedTx) {
    cbReportError({
      error: EthereumError.transactionSignatureMissing,
      context: 'transactions',
      severity: 'error',
      isHandled: false,
    });
    throw EthereumError.transactionSignatureMissing;
  }

  tracer.startSpan('on_submit');
  onSubmit(submittedTx);
  tracer.endSpan('on_submit');

  tracer.done();
  return signedTxOrUserOp;
}
